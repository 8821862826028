import React, { useEffect, useState } from "react";
import { Pagination, Stack ,Button} from "@mui/material";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import * as XLSX from "xlsx"; // Import xlsx

function VipFranchiseTable({ filterData }) {
  let [data, setUserData] = useState([]);
  const { userToken, userRole, profileData, setProfileViewData } = useGlobalContext();
  const navigate = useNavigate();
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  
  // Calculate the data to be shown on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  // Filter the data based on filterData
  const filteredData = data.filter((item) => {
    const lowerCaseFilter = filterData.toLowerCase();
    return (
      item.userName.toLowerCase().includes(lowerCaseFilter) ||
      item.email.toLowerCase().includes(lowerCaseFilter) ||
      item.phoneNumber.includes(lowerCaseFilter) ||
      item.whatsappNumber.includes(lowerCaseFilter) ||
      item.otherDetails.pinCodes?.join(",").includes(lowerCaseFilter)
    );
  });
  
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
  const fetchVIPFranchise = async () => {
    const header = {
      userRole: userRole === "hos" ? "admin" : userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${url}/admin/vipfranchise`, header);
      setUserData(response);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };
  
  useEffect(() => {
    fetchVIPFranchise();
  }, []);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(currentData.map((user, i) => ({
      S_no: indexOfFirstItem + i + 1,
      Vip_Fran_ID: `VIPF${indexOfFirstItem + i + 1}`,
      Vip_Fran_Name: user.userName,
      No_of_Pincodes: user.otherDetails.pinCodes?.length,
      Alloted_Pincodes: user.otherDetails.pinCodes?.join(","),
      Total_Collection: "30000", // Replace with actual value if available
      Email: user.email,
      Phone_Number: user.phoneNumber,
      User_Status: user.status,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VIP Franchise Data");
    
    XLSX.writeFile(workbook, "vip_franchise_data.xlsx");
  };
  return (
    <ViewvipfranchiseContainer>
      <div id="viewvipFranchise-table-container">
      <Button
            variant="outlined"
             className="download-btn"
            onClick={handleDownloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88", 
            }}
            disabled={currentData?.length === 0}
          >
            Download Excel
          </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">S No</th>
                  <th className="t-head" scope="col">VIP Franchise ID</th>
                  <th className="t-head" scope="col">VIP Franchise Name</th>
                  <th className="t-head" scope="col">No of Pincodes</th>
                  <th className="t-head" scope="col">Alloted Pincodes</th>
                  
                  <th className="t-head" scope="col">Email</th>
                  <th className="t-head" scope="col">Phone Number</th>
                  <th className="t-head" scope="col">User Status</th>
                  <th className="t-head rounded-end-3" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentData?.map((user, i) => (
                  <tr key={i}>
                    <td>{indexOfFirstItem + i + 1}</td>
                    <td>{`VIPF${indexOfFirstItem + i + 1}`}</td>
                    <td>{user.userName}</td>
                    <td>{user.otherDetails.pinCodes?.length}</td>
                    <td>{user.otherDetails.pinCodes?.join(",")}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber}</td>
                    <td>
                    <span
                        style={{
                          display: "inline-block", // To ensure the span behaves like a block-level element
                          padding: "5px 10px", // Padding for the box
                          borderRadius: "5px", // Rounded corners for the box
                          color: user.status === "active" ? "green" : "red", // Text color matches the border color
                          border: `2px solid ${user.status === "active" ? "green" : "red"}`, // Conditional border color
                          fontSize: "14px", // Responsive font size
                          textAlign: "center", // Center the text inside the box
                          width: "auto", // Adjust width based on content
                          minWidth: "80px", // Minimum width for small screens
                          maxWidth: "100%", // Full width on smaller screens
                        }}
                      >
                      {user.status}
                      </span>
                      </td>
                    <td>
                      <span
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          padding: "0.6rem",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setProfileViewData(user);
                          navigate("/profile");
                        }}
                      >
                        View Profile
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
      <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ViewvipfranchiseContainer>
  );
}

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;

const ViewvipfranchiseContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default VipFranchiseTable;
