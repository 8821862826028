import React, { useDebugValue, useEffect, useState } from "react";
import styled from "styled-components";
import { fetchData, postData } from "../../../Api";
import { URL } from "../../../assets/mocData/config";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";
import { Button, Stack, Pagination, FormControlLabel } from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import logo from '../../../assets/images/TiaR.jpg';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Box } from '@mui/material';


function Accountsvip({ filterData }) {

  const generateReceipt = (vip) => {
    const doc = new jsPDF();
    
    if (!vip || !vip.payments || vip.payments.length === 0) {
      enqueueSnackbar('No payment details available.', { variant: 'warning' });
      return;
    }
  
    // Set global font size to 8
    doc.setFontSize(8);
  
    // Function to check if there's enough space on the page and add a new page if needed
    const checkAndAddPage = (currentY, additionalSpace = 10) => {
      if (currentY + additionalSpace > doc.internal.pageSize.height) {
        doc.addPage();
        return 10; // Reset Y position to top on the new page
      }
      return currentY;
    };

    const logoWidth =35; // Width of the logo
    const logoHeight =35; // Height of the logo
  
    // Add logo to the right side
    doc.addImage(logo, 'PNG', 20, 12, logoWidth, logoHeight); // Adjust Y position to center vertically with text
    
    // Add company information at the top
    let currentY = 20;
    doc.text("TALENT INITIATORS AND ACCELERATORS INDIA PRIVATE LIMITED",75, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
    doc.text("51/1, P&T Colony, Chettipalayam Road, Podanur, Coimbatore-641023", 75, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
    doc.text("Mobile: 7373100400. Email: tiaedu.in@gmail.com, Website:www.tiaedu.in",75, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
        
   // Generate two random 3-digit numbers
    const firstPart = Math.floor(100 + Math.random() * 900);
    const secondPart = Math.floor(100 + Math.random() * 900);

   // Add Invoice number with a slash between the two parts
   doc.text(`Invoice No.: ${firstPart}/${secondPart}`, 150, currentY + 8);
   currentY += 10;




    currentY = checkAndAddPage(currentY);
    doc.text(`Date: ${new Date().toLocaleDateString('en-GB')}`, 150, currentY+8);
  
    // Add client information
    currentY = checkAndAddPage(currentY);
    doc.text(`Name: ${vip.userData.userName}`,20, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
    doc.text(`Phone Number: ${vip.userData.phoneNumber}`, 20, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
    doc.text(`Email: ${vip.userData.email}`, 20, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);
    
    // Display Total Payment
    const totalPayment = vip.sumValues.additionalrevenue + vip.sumValues.revenue;
    doc.text(`Total Payment: ${totalPayment}`, 20, currentY);
    
    currentY += 10;
    // Set font to bold and set font size
    doc.setFont("helvetica", "bold"); // Set to bold
    doc.setFontSize(14); // Set the desired font size (e.g., 16)
    
    // Add the "STATEMENT" text
    doc.text(`STATEMENT`, 80, currentY);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    
    // Function to format date as dd/mm/yyyy
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    // Prepare payment rows with serial number (S.No) and formatted date
    let cumulativePaid = 0;
    const paymentRows = vip.payments.map((payment, index) => {
      cumulativePaid += payment.amount;
      return [
        index + 1,
        formatDate(payment.date),
        payment.amount,
        payment.paymentStatus,
      ];
    });
  
    // Draw table with autoTable plugin
    currentY += 10;
    currentY = checkAndAddPage(currentY, 20);
    doc.autoTable({
      startY: currentY,
      head: [["S.No", "Received Date", "Amount", "Status"]],
      body: paymentRows,
      styles: { fontSize: 8 }, // Set font size in the table to 8
      didDrawPage: (data) => {
        currentY = data.cursor.y; // Update currentY with the table's ending Y position
      }
    });
  
    // Display pending payment if any
   
    // Footer
    currentY += 20;
    currentY = checkAndAddPage(currentY);
    doc.text("Authorized Signatory", 150, currentY);
    currentY += 20;
    currentY = checkAndAddPage(currentY);
    doc.text("For more information about TIA and our services, please visit our website:www.tiaedu.in", 40, currentY);
    currentY += 10;
    currentY = checkAndAddPage(currentY);

  
    // Save the PDF
    doc.save(`${vip.userData.userName}_Receipt.pdf`);
  };
  

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  let [paymentData, setPaymentData] = useState({
    amount: 0,
    description: "",
    paymentStatus: "",
    date: Date.now(),
  });
  const month = new Date().getMonth();

  const [itemsPerPage] = useState(50);
  const currentMonthIndex = new Date().getMonth();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [otpContainer, setOtpContainer] = useState(false);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[currentMonthIndex]
  );
  let [currentMonthnow, setMonth] = useState(currentMonth);
  const [open, setOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});

  const [comments, setComments] = useState("");
  const [paidChecked, setPaidChecked] = useState(false);
  const [cancelledChecked, setCancelledChecked] = useState(false);
  const handleMonthChange = (event) => {
    setSelectedMonth(monthNames[event.target.value - 1]);
    setMonth(parseInt(event.target.value));
  };
  const [pendingPayment, setPendingPayment] = useState(0);

  const handleOpen = (vip) => {
    setUser(vip);
    const pendingPayment = vip.sumValues.additionalrevenue + vip.sumValues.revenue - vip.sumValues.amountSum;
    setPendingPayment(pendingPayment); // Add this line to store the pending payment
    setShow(true);
  };
  
  const handleClose = () => {
    setShow(false);
    setUser({});
  };

  let [userData, setAllUserData] = useState([]);
console.log(userData)
  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };
  const filterSalaryDetails = (user, currentMonthnow, selectedYear) => {
    const formattedDate =
      currentYear.toString() + "-" + "0" + currentMonthnow.toString();

    const currentSalaryTransaction = user.transactions?.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );

    const currentMonthPayment = user.transactionsRecieved.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );
    
    let amountSum = calculateTotalAmount(currentMonthPayment);
    let sumData = sumTransactionAmounts(currentSalaryTransaction);

    return {
      userData: user,
      payments: currentMonthPayment,
      transcations: currentSalaryTransaction,
      sumValues: { ...sumData, amountSum: amountSum },
    };
  };
  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/allvip`, header);
      setVipData(response.allVips);

      let users = await response.allVips.map((user) => {
        return filterSalaryDetails(user, currentMonthnow, selectedYear);
      });

      setAllUserData(users);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };
  console.log(currentMonthnow);
  const [selectedDescription, setSelectedDescription] = useState('');

  const submitTransaction = async (e, userId) => {
    e.preventDefault();
    
  
    // Parse the amount from the form input
    const enteredAmount = parseFloat(e.target["amount"].value);
    if (!enteredAmount) {
      enqueueSnackbar('Please enter the amount', { variant: 'warning' });
      return;
    }
    // Check if the amount is valid
    if (isNaN(enteredAmount) || enteredAmount <= 0) {
      enqueueSnackbar("Please enter a valid amount.", { variant: "warning" });
      return; // Stop if the amount is invalid
    }
  
    // Check if the entered amount exceeds the pending payment
    if (enteredAmount > pendingPayment) {
      enqueueSnackbar(`Amount cannot exceed the pending payment of ${pendingPayment}.`, { variant: "error" });
      return; // Stop if the amount exceeds the pending payment
    }
  
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    const transactionData = {
      amount: enteredAmount, // Use the validated amount
      description: e.target["description"].value,
      paymentStatus: e.target["paymentStatus"].value,
      month: currentMonthnow,
    };
  
    try {
      const response = await postData(
        `${URL}/accounts/transaction/${userId}`,
        transactionData,
        header
      );
      window.location.reload();
      console.log("Transaction successfully added:", response.data);
      // Handle success (optional): reset form, show a message, etc.
    } catch (error) {
      console.error("Error adding transaction:", error.response.data);
      // Handle the error accordingly
    }
  };
  
  const refereshTranscation = async (vipData) => {
    let users = await vipData.map((user) => {
      return filterSalaryDetails(user, currentMonthnow, selectedYear);
    });
    console.log(user);
    setAllUserData(users);
  };
  // Filter the userData array based on the filterData input
  const filteredData = userData.filter((vip) => {
    const vipName = vip.userData.userName?.toLowerCase() || "";
    const phoneNumber = vip.userData.phoneNumber?.toLowerCase() || "";
    const email = vip.userData.email?.toLowerCase() || "";

    return (
      vipName.includes(filterData.toLowerCase()) ||
      phoneNumber.includes(filterData.toLowerCase()) ||
      email.includes(filterData.toLowerCase())
    );
  });

  const getTotalPages = (filteredData) => {
    return Math.ceil(filteredData?.length / itemsPerPage);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    if (vipData) {
      refereshTranscation(vipData, currentMonthnow, selectedYear);
    }
  }, [currentMonthnow, selectedYear]);
  useEffect(() => {
    fetchVip();
  }, []);
  console.log(selectedYear);
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((vip, i) => ({
        "S.No": i + 1,
        "Vip Name": vip.userData.userName,
        "Phone Number": vip.userData.phoneNumber,
        "Email": vip.userData.email,
        "Collection": vip.sumValues.collection,
        "Revenue": vip.sumValues.revenue,
        "Additional Revenue": vip.sumValues.additionalrevenue,
        "Total Payment": vip.sumValues.additionalrevenue + vip.sumValues.revenue,
        "Payment Paid": vip.sumValues.amountSum,
        "Payment Pending": vip.sumValues.additionalrevenue + vip.sumValues.revenue - vip.sumValues.amountSum,
      }))
    );
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "VIP Data");
  
    // Export the workbook
    XLSX.writeFile(wb, "vip_data.xlsx");
  };
  const paginatedData = userData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  } else {
    return (
      <AccountsvipContainer style={{ marginTop: "1rem" }}>
        <CenterGroup>
          <Select
            value={currentMonthnow}
            onChange={(e) => handleMonthChange(e)}
            name=""
            id=""
          >
            {monthNames.map((month, i) => {
              return (
                <option key={`${month}`} value={i + 1}>
                  {month}
                </option>
              );
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </CenterGroup>
        <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S.No
                  </th>
                  <th className="t-head" scope="col">
                    Vip Name
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Collection
                  </th>
                  <th className="t-head" scope="col">
                    Revenue
                  </th>
                  <th className="t-head" scope="col">
                    Additional Revenue
                  </th>
                  <th className="t-head" scope="col">
                    Total Payment
                  </th>
                  <th className="t-head" scope="col">
                    Payment Paid
                  </th>
                  <th className="t-head" scope="col">
                    Payment Pending
                  </th>
                  <th className="t-head" scope="col">
                    Actions
                  </th>
                  <th className="t-head" scope="col">
                    View Stats
                  </th>
                  <th className="t-head " scope="col">
                    Payment Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Download Receipt
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {paginatedData?.map((vip, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{vip.userData.userName}</td>
                      <td>{vip.userData.phoneNumber}</td>
                      <td>{vip.userData.email}</td>
                      <td>{vip.sumValues.collection}</td>
                      <td>{vip.sumValues.revenue}</td>
                      <td>{vip.sumValues.additionalrevenue}</td>
                      <td>
                        {vip.sumValues.additionalrevenue +
                          vip.sumValues.revenue}
                      </td>
                      <td>{vip.sumValues.amountSum}</td>
                      <td>
                        {vip.sumValues.additionalrevenue +
                          vip.sumValues.revenue -
                          vip.sumValues.amountSum}
                      </td>
                      <td>
                      <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                           handleOpen(vip);
                          }}
                        >
                         Action
                        </div>
                      </td>
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewStatsContainer(vip);
                          }}
                        >
                          View Stats
                        </div>
                      </td>
                      <td>
                      <span
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: vip.sumValues.additionalrevenue + vip.sumValues.revenue - vip.sumValues.amountSum === 0 ? "green" : "yellow",
                          border: `1px solid ${
                            vip.sumValues.additionalrevenue + vip.sumValues.revenue - vip.sumValues.amountSum === 0
                              ? "green"
                              : "yellow"
                          }`,
                          padding: "0.6rem",
                          textAlign: "center",
                        }}
                      >
                        {vip.sumValues.additionalrevenue + vip.sumValues.revenue - vip.sumValues.amountSum === 0
                          ? "Paid"
                          : "Pending"}
                      </span>
                    </td>
                    <td><DownloadingIcon style={{ color: '#f00d88'}}
                     onClick={() => generateReceipt(vip)}/></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
        {show && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const paymentStatus = document.querySelector('input[name="paymentStatus"]:checked');
                  if (!paymentStatus) {
                    enqueueSnackbar("Please select a payment status.", { variant: "warning" });
                    return;
                  }
                  submitTransaction(e, user.userData.userId);
                }}
                id="otp-container"
                style={{
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0 auto',
                  padding: '1rem',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#25272d', // Dark background
                }}
              >
                <span style={{ position: "absolute", right: "10px", top: "5px" }}>
                  <Icon
                    onClick={() => setShow(false)}
                    style={{ color: "var(--icon-color)", cursor: "pointer" }}
                    icon={cross}
                  />
                </span>
                <input
                  type="number"
                  name="amount"
                  placeholder="Amount"
                  className="custom-input"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  step="0.01"
                  required
                />
                <select
                  name="description"
                  className="custom-dropdown"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  defaultValue=""
                  required
                >
                  <option value="" disabled hidden>Select a description</option>
                  <option value="Success">Success</option>
                  <option value="Wrong Entry">Wrong Entry</option>
                  <option value="Wrong Payment">Wrong Payment</option>
                  <option value="Other">Other</option>
                </select>
                <Box display="flex" flexDirection="column" gap="10px" mt={2}>
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="paid"
                        style={{ accentColor: "green", marginRight: "8px" }}
                      />
                    }
                    label="Paid"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="cancelled"
                        style={{ accentColor: "red", marginRight: "8px" }}
                      />
                    }
                    label="Cancelled"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{
                    width: '30%',
                    marginTop: "1.5rem",
                    borderColor: "#f00d88",
                    color: "#f00d88",
                  }}
                >
                  Submit
                </Button>
              </form>
            )}
      </AccountsvipContainer>
    );
  }
}

function AccountsVipFranchise({ filterData }) {

  const generateReceipt = (vip) => {
  if (!vip || !vip.payments || vip.payments.length === 0) {
    enqueueSnackbar('No payment data available.', { variant: 'warning' });
    return;
  }
  const doc = new jsPDF();
  
  // Set consistent font size to 8
  doc.setFontSize(8);

  // Helper function to add text with page check
  const addTextWithPageCheck = (text, x, y) => {
    if (y > 280) {  // Checks if y-coordinate is near the page bottom
      doc.addPage();
      y = 20;       // Reset y to start from the top on the new page
    }
    doc.text(text, x, y);
    return y + 10; // Increment y position for the next line
  };

  const logoWidth =35; // Width of the logo
    const logoHeight =35; // Height of the logo
  
    // Add logo to the right side
    doc.addImage(logo, 'PNG', 30,4, logoWidth, logoHeight); // Adjust Y position to center vertically with text
  // Add company information at the top
  let yPos = 10;
  yPos = addTextWithPageCheck("TALENT INITIATORS AND ACCELERATORS INDIA PRIVATE LIMITED", 75, yPos);
  yPos = addTextWithPageCheck("51/1, P&T Colony, Chettipalayam Road, Podanur, Coimbatore-641023",75, yPos);
  yPos = addTextWithPageCheck("Mobile: 7373100400. Email: tiaedu.in@gmail.com, Website:www.tiaedu.in",75, yPos);
  

       
   // Generate two random 3-digit numbers
   const firstPart = Math.floor(100 + Math.random() * 900);
   const secondPart = Math.floor(100 + Math.random() * 900);

  // Add Invoice number with a slash between the two parts
  doc.text(`Invoice No.: ${firstPart}/${secondPart}`, 150, yPos+10);
  yPos = addTextWithPageCheck(`Date: ${new Date().toLocaleDateString('en-GB')}`, 150, yPos+20);

  // Add client information
  yPos = addTextWithPageCheck(`To: ${vip.userData.userName}`, 20, yPos-15);
  yPos = addTextWithPageCheck(`Phone Number: ${vip.userData.phoneNumber}`, 20, yPos );
  yPos = addTextWithPageCheck(`Email: ${vip.userData.email}`, 20, yPos);

  // Display Total Payment below email
  const totalPayment = vip.sumValues.additionalrevenue + vip.sumValues.revenue;
  yPos = addTextWithPageCheck(`Total Payment: ${totalPayment}`, 20, yPos );

  doc.setFont("helvetica", "bold"); // Set to bold
  doc.setFontSize(14); // Set the desired font size (e.g., 16)
  
  // Add the "STATEMENT" text
  doc.text(`STATEMENT`, 90, yPos);
  
  // Optionally, reset font size and style for the rest of the document
  doc.setFont("helvetica", "normal"); // Reset to normal font
  doc.setFontSize(8); 
  // Format date as dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Prepare payment rows with serial number (S.No) and formatted date
  let cumulativePaid = 0;
  const paymentRows = vip.payments.map((payment, index) => {
    cumulativePaid += payment.amount;
    return [
      index + 1,                            // S.No
      formatDate(payment.date),              // Received Date
      payment.amount,                        // Amount
      payment.paymentStatus,                 // Status
    ];
  });

  // Draw table with autoTable plugin
  doc.autoTable({
    startY: yPos + 10,
    head: [["S.No", "Received Date", "Amount", "Status"]],
    body: paymentRows,
    styles: { fontSize: 8 },  // Set font size to 8 for the table
  });

  

  // Footer
  yPos = doc.lastAutoTable.finalY + 40;
  yPos = addTextWithPageCheck("Authorized Signatory", 160, yPos);
  yPos = addTextWithPageCheck("For more information about TIA and our services, please visit our website:www.tiaedu.in", 50, yPos + 20);


  // Save PDF
  doc.save(`${vip.userData.userName}_Receipt.pdf`);
};



  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let [paymentData, setPaymentData] = useState({
    amount: 0,
    description: "",
    paymentStatus: "",
    date: Date.now(),
  });

  const month = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[currentMonthIndex]
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [otpContainer, setOtpContainer] = useState(false);

  const handleMonthChange = (event) => {
    setSelectedMonth(monthNames[event.target.value - 1]);
    setMonth(parseInt(event.target.value));
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  let [currentMonthnow, setMonth] = useState(currentMonth);
  const [open, setOpen] = React.useState(false);

  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});

  const [comments, setComments] = useState("");
  const [paidChecked, setPaidChecked] = useState(false);
  const [cancelledChecked, setCancelledChecked] = useState(false);

  const handleOpen = (user) => {
    setUser(user);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setUser({});
  };

  let [userData, setAllUserData] = useState([]);

  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };
  console.log("ok", selectedYear);
  const filterSalaryDetails = (user, currentMonthnow, selectedYear) => {
    const formattedDate =
      currentYear.toString() + "-" + "0" + currentMonthnow.toString();

    const currentSalaryTransaction = user.transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.date);
      console.log(
        "transactionDate",
        transactionDate.getFullYear(),
        selectedYear
      );
      return (
        transactionDate.getMonth() + 1 === currentMonthnow &&
        transactionDate.getFullYear() === parseInt(selectedYear)
      );
    });

    const currentMonthPayment = user.transactionsRecieved.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );
    console.log(currentMonthPayment);
    let amountSum = calculateTotalAmount(currentMonthPayment);
    let sumData = sumTransactionAmounts(currentSalaryTransaction);

    return {
      userData: user,
      payments: currentMonthPayment,
      transcations: currentSalaryTransaction,
      sumValues: { ...sumData, amountSum: amountSum },
    };
  };
  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/allvipfranchise`, header);
      console.log(response);
      setVipData(response.allVipFranchises);

      let users = await response.allVipFranchises.map((user) => {
        return filterSalaryDetails(user, currentMonthnow, selectedYear);
      });

      setAllUserData(users);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const submitTransaction = async (e, userId) => {
    e.preventDefault();
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    
    // Get the payment amount from the form input
    const paymentAmount = parseFloat(e.target["amount"].value);
    
    // Find the corresponding VIP data from userData using userId
    const vip = userData.find(v => v.userData.userId === userId);
    if (!vip) {
      enqueueSnackbar("User not found", { variant: "error" });
      return;
    }
    
    const paymentPending = vip.sumValues.revenue - vip.sumValues.amountSum;
  
    // Check if the payment amount exceeds the pending amount
    if (paymentAmount > paymentPending) {
      enqueueSnackbar(`Payment amount cannot exceed the pending payment of ${paymentPending}`, { variant: "error" });
      return;
    }
  
    const transactionData = {
      amount: paymentAmount,
      description: e.target["description"].value,
      paymentStatus: e.target["paymentStatus"].value,
      month: currentMonthnow,
    };
  
    try {
      const response = await postData(
        `${URL}/accounts/transaction/${userId}`,
        transactionData,
        header
      );
      window.location.reload();
      console.log("Transaction successfully added:", response.data);
    } catch (error) {
      console.error("Error adding transaction:", error.response.data);
      // Handle the error accordingly
    }
  };
  
  const refereshTranscation = async (
    vipData,
    currentMonthnow,
    selectedYear
  ) => {
    let users = await vipData.map((user) => {
      return filterSalaryDetails(user, currentMonthnow, selectedYear);
    });
    console.log(user);
    setAllUserData(users);
  };
  const filteredData = userData.filter((vip) => {
    const vipName = vip.userData.userName?.toLowerCase() || "";
    const phoneNumber = vip.userData.phoneNumber?.toLowerCase() || "";
    const email = vip.userData.email?.toLowerCase() || "";

    return (
      vipName.includes(filterData.toLowerCase()) ||
      phoneNumber.includes(filterData.toLowerCase()) ||
      email.includes(filterData.toLowerCase())
    );
  });

  const getTotalPages = (filteredData) => {
    return Math.ceil(filteredData?.length / itemsPerPage);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    if (vipData) {
      refereshTranscation(vipData, currentMonthnow, selectedYear);
    }
  }, [currentMonthnow, selectedYear]);
  useEffect(() => {
    fetchVip();
  }, []);
  const exportToExcel = () => {
    // Prepare data for the Excel file
    const data = filteredData.map((vip, index) => ({
      'S.no': index + 1,
      'Vip Franchise Name': vip.userData.userName,
      'Phone Number': vip.userData.phoneNumber,
      'Email': vip.userData.email,
      'Collection': vip.sumValues.collection,
      'Revenue': vip.sumValues.revenue,
      'Total Payment': vip.sumValues.revenue,
      'Payment Paid': vip.sumValues.amountSum,
      'Payment Pending': vip.sumValues.revenue - vip.sumValues.amountSum,
    }));

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'VIP Franchises');

    // Save the workbook
    XLSX.writeFile(workbook, 'vip_franchises.xlsx');
  };
  const paginatedData = userData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  } else {
    return (
      <AccountsvipContainer style={{ marginTop: "1rem" }}>
        <CenterGroup>
          <Select
            value={currentMonthnow}
            onChange={(e) => handleMonthChange(e)}
            name=""
            id=""
          >
            {monthNames.map((month, i) => {
              return (
                <option key={`${month}`} value={i + 1}>
                  {month}
                </option>
              );
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </CenterGroup>
        <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S.No
                  </th>
                  <th className="t-head" scope="col">
                    Vip Franchise Name
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Collection
                  </th>
                  <th className="t-head" scope="col">
                    Revenue
                  </th>

                  <th className="t-head" scope="col">
                    Total Payment
                  </th>
                  <th className="t-head" scope="col">
                    Payment Paid
                  </th>
                  <th className="t-head" scope="col">
                    Payment Pending
                  </th>
                  <th className="t-head" scope="col">
                    Actions
                  </th>
                  <th className="t-head" scope="col">
                    View Stats
                  </th>
                  <th className="t-head" scope="col">
                    Payment Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Download Receipt
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {paginatedData?.map((vip, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  const paymentPending = vip.sumValues.revenue - vip.sumValues.amountSum;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{vip.userData.userName}</td>
                      <td>{vip.userData.phoneNumber}</td>
                      <td>{vip.userData.email}</td>
                      <td>{vip.sumValues.collection}</td>
                      <td>{vip.sumValues.revenue}</td>
                      <td>{vip.sumValues.revenue}</td>
                      <td>{vip.sumValues.amountSum}</td>
                      <td>{vip.sumValues.revenue - vip.sumValues.amountSum}</td>
                      <td>
                      <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                           handleOpen(vip);
                          }}
                        >
                         Action
                        </div>
                      </td>
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewStatsContainer(vip);
                          }}
                        >
                          View Stats
                        </div>
                      </td>
                      <td>
          <span
            className="badge"
            style={{
              height: "80%",
              width: "100%",
              color: paymentPending === 0 ? "green" : "yellow",
              border: paymentPending === 0 ? "1px solid green" : "1px solid yellow",
              padding: "0.6rem",
              textAlign: "center",
            }}
          >
            {paymentPending === 0 ? "Paid" : "Pending"}
          </span>
        </td>
       <td><DownloadingIcon style={{ color: '#f00d88'}}
        onClick={() => generateReceipt(vip)}/></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
        {show && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const paymentStatus = document.querySelector('input[name="paymentStatus"]:checked');
                  if (!paymentStatus) {
                    enqueueSnackbar("Please select a payment status.", { variant: "warning" });
                    return;
                  }
                  submitTransaction(e, user.userData.userId);
                }}
                id="otp-container"
                style={{
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0 auto',
                  padding: '1rem',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#25272d', // Dark background
                }}
              >
                <span style={{ position: "absolute", right: "10px", top: "5px" }}>
                  <Icon
                    onClick={() => setShow(false)}
                    style={{ color: "var(--icon-color)", cursor: "pointer" }}
                    icon={cross}
                  />
                </span>
                <input
                  type="number"
                  name="amount"
                  placeholder="Amount"
                  className="custom-input"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  step="0.01"
                  required
                />
                <select
                  name="description"
                  className="custom-dropdown"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  defaultValue=""
                  required
                >
                  <option value="" disabled hidden>Select a description</option>
                  <option value="Success">Success</option>
                  <option value="Wrong Entry">Wrong Entry</option>
                  <option value="Wrong Payment">Wrong Payment</option>
                  <option value="Other">Other</option>
                </select>
                <Box display="flex" flexDirection="column" gap="10px" mt={2}>
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="paid"
                        style={{ accentColor: "green", marginRight: "8px" }}
                      />
                    }
                    label="Paid"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="cancelled"
                        style={{ accentColor: "red", marginRight: "8px" }}
                      />
                    }
                    label="Cancelled"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{
                    width: '30%',
                    marginTop: "1.5rem",
                    borderColor: "#f00d88",
                    color: "#f00d88",
                  }}
                >
                  Submit
                </Button>
              </form>
            )}
      </AccountsvipContainer>
    );
  }
}
function AccountsFranchise({ filterData }) {
  
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let [paymentData, setPaymentData] = useState({
    amount: 0,
    description: "",
    paymentStatus: "",
    date: Date.now(),
  });
  const month = new Date().getMonth();
  const [currentPage, setCurrentPage] = useState(1);
  const getTotalPages = (Coursedata) => {
    return Math.ceil(Coursedata?.length / itemsPerPage);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setCurrentPage(value);
  };
  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Initialize state
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[currentMonthIndex]
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleMonthChange = (event) => {
    setSelectedMonth(monthNames[event.target.value - 1]);
    setMonth(parseInt(event.target.value));
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const [itemsPerPage] = useState(50);

  const [otpContainer, setOtpContainer] = useState(false);

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  let [currentMonthnow, setMonth] = useState(currentMonth);
  const [open, setOpen] = React.useState(false);

  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});

  const [comments, setComments] = useState("");
  const [paidChecked, setPaidChecked] = useState(false);
  const [cancelledChecked, setCancelledChecked] = useState(false);

  const handleOpen = (user) => {
    console.log(user);
    setUser(user);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setUser({});
  };

  let [userData, setAllUserData] = useState([]);

  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };

  const filterSalaryDetails = (user, currentMonthnow, selectedYear) => {
    console.log(user.transactions);
    const formattedDate =
      currentYear.toString() + "-" + "0" + currentMonthnow.toString();
    const currentMonthTransaction = user.transactionsRecieved.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );
    const currentSalaryTransaction = user.salaryTranscation.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );

    console.log(currentMonthTransaction, "currentSalaryTransaction");
    let sumData = calculateTotalAmount(currentMonthTransaction);
    return {
      user: user,
      royalityPayments: currentSalaryTransaction[0],
      sumMoney: sumData,
      payments: currentMonthTransaction,
    };
  };
  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/allfranchise`, header);
      console.log(response);
      setVipData(response.allFranchises);

      let users = await response.allFranchises.map((user) => {
        return filterSalaryDetails(user, currentMonthnow, selectedYear);
      });

      setAllUserData(users);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };

  const submitTransaction = async (e, userId) => {
    e.preventDefault();
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    const transactionData = {
      amount: e.target["amount"].value,
      description: e.target["description"].value,
      paymentStatus: e.target["paymentStatus"].value,
      month: currentMonthnow,
    };

    try {
      const response = await postData(
        `${URL}/accounts/transaction/${userId}`,
        transactionData,
        header
      );
      window.location.reload();
      console.log("Transaction successfully added:", response.data);
      // You can handle success by resetting the form, showing a message, etc.
    } catch (error) {
      console.error("Error adding transaction:", error.response.data);
      // Handle the error accordingly
    }
  };
  const refereshTranscation = async (
    vipData,
    currentMonthnow,
    selectedYear
  ) => {
    let users = await vipData.map((user) => {
      return filterSalaryDetails(user, currentMonthnow, selectedYear);
    });
    console.log(user);
    setAllUserData(users);
  };
  

  const generateReceipt = (user) => {
    if (!user.payments || user.payments.length === 0) {
      enqueueSnackbar('No payment data available.', { variant: 'warning' });
      return;
    }
    
    const doc = new jsPDF();
    
    // Set consistent font size to 8
    doc.setFontSize(8);
  
    // Helper function to add text with page check
    const addTextWithPageCheck = (text, x, y) => {
      if (y > 280) {  // Check if y-coordinate is near the bottom of the page
        doc.addPage();
        y = 20;       // Reset y to start from the top of the new page
      }
      doc.text(text, x, y);
      return y + 10; // Increment y position for the next line
    };

    const logoWidth =35; // Width of the logo
    const logoHeight =35; // Height of the logo
  
    // Add logo to the right side
    doc.addImage(logo, 'PNG', 20,1, logoWidth, logoHeight); // Adjust Y position to center vertically with text
    // Add company information at the top
    let yPos = 10;
    yPos = addTextWithPageCheck("TALENT INITIATORS AND ACCELERATORS INDIA PRIVATE LIMITED",75, yPos);
    yPos = addTextWithPageCheck("51/1, P&T Colony, Chettipalayam Road, Podanur, Coimbatore-641023",75, yPos);
    yPos = addTextWithPageCheck("Mobile: 7373100400. Email: tiaedu.in@gmail.com, Website: WWW.TIAEDU.IN",75, yPos);
    
  
    // Add Invoice number and Date
   // Generate two random 3-digit numbers
   const firstPart = Math.floor(100 + Math.random() * 900);
   const secondPart = Math.floor(100 + Math.random() * 900);

// Add Invoice number with a slash between the two parts
   doc.text(`Invoice No: ${firstPart}/${secondPart}`, 150, yPos+10);


    yPos = addTextWithPageCheck(`Date: ${new Date().toLocaleDateString('en-GB')}`, 150, yPos+20);
  
    // Add client information
    yPos = addTextWithPageCheck(`Name: ${user.user.userName}`, 20, yPos-20) ;
    yPos = addTextWithPageCheck(`Phone Number: ${user.user.phoneNumber}`, 20, yPos );
    yPos = addTextWithPageCheck(`Email: ${user.user.email}`, 20, yPos );
  
    // Display Royalty Amount
    const totalPayment = user.royalityPayments?.amount;
    yPos = addTextWithPageCheck(`Royalty Amount: ${totalPayment}`, 20, yPos );
  
    // Format date as dd/mm/yyyy
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    doc.setFont("helvetica", "bold"); // Set to bold
doc.setFontSize(14); // Set the desired font size (e.g., 16)

// Add the "STATEMENT" text
doc.text(`STATEMENT`, 90, yPos);

// Optionally, reset font size and style for the rest of the document
doc.setFont("helvetica", "normal"); // Reset to normal font
doc.setFontSize(8); // Reset fo
    // Prepare payment rows with serial number (S.No) and formatted date
    let cumulativePaid = 0;
    const paymentRows = user.payments.map((payment, index) => {
      cumulativePaid += payment.amount;
      return [
        index + 1,                                // S.No
        formatDate(payment.date),                  // Received Date
        payment.amount,                            // Amount
        payment.paymentStatus,                     // Status
      ];
    });
  
    // Draw table with autoTable plugin
    doc.autoTable({
      startY: yPos + 10,
      head: [["S.No", "Received Date", "Amount", "Status"]],
      body: paymentRows,
      styles: { fontSize: 8 },  // Set font size to 8 for the table
    });
  
    // Check for pending amount
    const pendingAmount = totalPayment - user.sumMoney;
    if (pendingAmount !== 0) {
      yPos = doc.lastAutoTable.finalY + 20;
      yPos = addTextWithPageCheck(`Amount Pending: ${pendingAmount}`, 20, yPos);
    }
  
    // Footer
    yPos = doc.lastAutoTable.finalY + 20;
    yPos = addTextWithPageCheck("Authorized Signatory", 160, yPos);
    yPos = addTextWithPageCheck("For more information about TIA and our services, please visit our website: WWW.TIAEDU.IN", 60, yPos + 20);
  
    // Save PDF
    doc.save(`${user.user.userName}_Receipt.pdf`);
  };
  
  const [filteredData, setFilteredData] = useState([]);
  // Filter logic based on Franchise Owner Name or Email
  useEffect(() => {
    if (filterData) {
      const filtered = userData.filter((user) => {
        return (
          user.user.userName.toLowerCase().includes(filterData.toLowerCase()) ||
          user.user.email.toLowerCase().includes(filterData.toLowerCase())
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(userData); // If no search query, show all data
    }
  }, [filterData, userData]);

  useEffect(() => {
    if (vipData) {
      refereshTranscation(vipData, currentMonthnow, selectedYear);
    }
  }, [currentMonthnow, selectedYear]);
  useEffect(() => {
    fetchVip();
  }, []);
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData.map((user, i) => ({
      SNo: i + 1,
      FranchiseOwnerName: user.user.userName,
      Email: user.user.email,
      RoyaltyAmount: user.royalityPayments?.amount,
      AmountPaid: user.sumMoney,
      AmountPending: user.royalityPayments?.amount - user.sumMoney,
      PaymentStatus: user.royalityPayments?.status || "Pending",
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Franchise Data");

    // Export the file
    XLSX.writeFile(wb, "franchise_data.xlsx");
  };
  const paginatedData = userData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  } else {
    return (
      <AccountsvipContainer style={{ marginTop: "1rem" }}>
        <CenterGroup>
          <Select
            value={currentMonthnow}
            onChange={(e) => handleMonthChange(e)}
            name=""
            id=""
          >
            {monthNames.map((month, i) => {
              return (
                <option key={`${month}`} value={i + 1}>
                  {month}
                </option>
              );
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </CenterGroup>
        <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S.No
                  </th>
                  <th className="t-head" scope="col">
                    Franchise Owner Name
                  </th>
                  {/* <th className="t-head" scope="col">
                    Franchise  ID
                  </th> */}
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Royalty Amount
                  </th>
                  <th className="t-head" scope="col">
                    Amount Paid
                  </th>
                  <th className="t-head" scope="col">
                    Amount Pending
                  </th>
                  <th className="t-head" scope="col">
                    Actions
                  </th>
                  <th className="t-head" scope="col">
                    View Stats
                  </th>
                  <th className="t-head" scope="col">
                    Payment Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Download Receipt
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {paginatedData?.map((user, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{user.user.userName}</td>
                      {/* <td>{user.user.user_id}</td> */}
                      <td>{user.user.email}</td>
                      <td>{user.royalityPayments?.amount||0}</td>
                      <td>{user.sumMoney}</td>
                      <td>{user.royalityPayments?.amount - user.sumMoney||0}</td>
                      <td>
                      <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                           handleOpen(user);
                          }}
                        >
                         Action
                        </div>
                      </td>
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewStatsContainer(user);
                          }}
                        >
                          View Stats
                        </div>
                      </td>
                      <td>
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "yellow",
                            border: "1px solid yellow",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Pending
                        </span>
                      </td>
                      <td>
                    <DownloadingIcon
                      onClick={() => generateReceipt(user)}
                      style={{ color: '#f00d88' }}
                    />
                  </td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
             {show && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const paymentStatus = document.querySelector('input[name="paymentStatus"]:checked');
                  if (!paymentStatus) {
                    enqueueSnackbar("Please select a payment status.", { variant: "warning" });
                    return;
                  }
                  submitTransaction(e, user.user.userId);
                }}
                id="otp-container"
                style={{
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0 auto',
                  padding: '1rem',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#25272d', // Dark background
                }}
              >
                <span style={{ position: "absolute", right: "10px", top: "5px" }}>
                  <Icon
                    onClick={() => setShow(false)}
                    style={{ color: "var(--icon-color)", cursor: "pointer" }}
                    icon={cross}
                  />
                </span>
                <input
                  type="number"
                  name="amount"
                  placeholder="Amount"
                  className="custom-input"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  step="0.01"
                  required
                />
                <select
                  name="description"
                  className="custom-dropdown"
                  style={{
                    width: '60%',
                    padding: '10px',
                    marginBottom: '1rem',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                  defaultValue=""
                  required
                >
                  <option value="" disabled hidden>Select a description</option>
                  <option value="Success">Success</option>
                  <option value="Wrong Entry">Wrong Entry</option>
                  <option value="Wrong Payment">Wrong Payment</option>
                  <option value="Other">Other</option>
                </select>
                <Box display="flex" flexDirection="column" gap="10px" mt={2}>
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="paid"
                        style={{ accentColor: "green", marginRight: "8px" }}
                      />
                    }
                    label="Paid"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <input
                        type="radio"
                        name="paymentStatus"
                        value="cancelled"
                        style={{ accentColor: "red", marginRight: "8px" }}
                      />
                    }
                    label="Cancelled"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{
                    width: '30%',
                    marginTop: "1.5rem",
                    borderColor: "#f00d88",
                    color: "#f00d88",
                  }}
                >
                  Submit
                </Button>
              </form>
            )}
      </AccountsvipContainer>
    );
  }
}

function AccountsHOstaff({ filterData }) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let [paymentData, setPaymentData] = useState({
    amount: 0,
    description: "",
    paymentStatus: "",
    date: Date.now(),
  });
  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Initialize state
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[currentMonthIndex]
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleMonthChange = (event) => {
    setSelectedMonth(monthNames[event.target.value - 1]);
    setMonth(parseInt(event.target.value));
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const month = new Date().getMonth();
  const [currentPage, setCurrentPage] = useState(1);
  const getTotalPages = (Coursedata) => {
    return Math.ceil(Coursedata?.length / itemsPerPage);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setCurrentPage(value);
  };
  const [itemsPerPage] = useState(10);
  const [otpContainer, setOtpContainer] = useState(false);

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  let [currentMonthnow, setMonth] = useState(currentMonth);
  const [open, setOpen] = React.useState(false);

  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});

  const [comments, setComments] = useState("");
  const [paidChecked, setPaidChecked] = useState(false);
  const [cancelledChecked, setCancelledChecked] = useState(false);

  const handleOpen = (user) => {
    console.log(user);
    setUser(user);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setUser({});
  };

  let [userData, setAllUserData] = useState([]);

  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };

  const filterSalaryDetails = (user, currentMonthnow) => {
    console.log(user.transactions);
    const formattedDate =
      currentYear.toString() + "-" + "0" + currentMonthnow.toString();
    const currentMonthTransaction = user.transactionsRecieved.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );
    const currentSalaryTransaction = user.salaryTranscation.filter(
      (transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() + 1 === currentMonthnow &&
          transactionDate.getFullYear() === parseInt(selectedYear)
        );
      }
    );

    console.log(currentMonthTransaction, "currentSalaryTransaction");
    let sumData = calculateTotalAmount(currentMonthTransaction);
    return {
      user: user,
      royalityPayments: currentSalaryTransaction,
      sumMoney: sumData,
      payments: currentMonthTransaction,
    };
  };
  const fetchHos = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/allhos`, header);
      console.log(response);
      setVipData(response.allHOS);

      let users = await response.allHOS.map((user) => {
        return filterSalaryDetails(user, currentMonthnow);
      });

      setAllUserData(users);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };

  const submitTransaction = async (e, userId) => {
    e.preventDefault();
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    const transactionData = {
      amount: e.target["amount"].value,
      description: e.target["description"].value,
      paymentStatus: e.target["paymentStatus"].value,
      month: currentMonthnow,
    };

    try {
      const response = await postData(
        `${URL}/accounts/transaction/${userId}`,
        transactionData,
        header
      );
      window.location.reload();
      console.log("Transaction successfully added:", response.data);
      // You can handle success by resetting the form, showing a message, etc.
    } catch (error) {
      console.error("Error adding transaction:", error.response.data);
      // Handle the error accordingly
    }
  };
  const refereshTranscation = async (vipData) => {
    let users = await vipData.map((user) => {
      return filterSalaryDetails(user, currentMonthnow);
    });
    console.log(user);
    setAllUserData(users);
  };
  // Filter userData based on filterData prop (search)
  const filteredData = userData.filter((user) => {
    const nameMatches = user.user.userName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const emailMatches = user.user.email
      .toLowerCase()
      .includes(filterData.toLowerCase());
    return nameMatches || emailMatches;
  });
  useEffect(() => {
    if (vipData) {
      refereshTranscation(vipData, currentMonthnow);
    }
  }, [currentMonthnow]);
  useEffect(() => {
    fetchHos();
  }, []);
  console.log(user);
  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    
    // Create data for the worksheet
    const wsData = userData.map((user, i) => [
      i + 1,
      user.user.userName,
      user.user.email,
      user.user.transactionDetail.salary,
      user.sumMoney,
      user.user.transactionDetail.salary - user.sumMoney,
    ]);

    // Add headers to the worksheet
    const ws = XLSX.utils.aoa_to_sheet([
      ['S.no', 'Staff Name', 'Email', 'Salary', 'Amount Paid', 'Amount Pending'],
      ...wsData,
    ]);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Staff Data');

    // Export the workbook
    XLSX.writeFile(wb, 'staff_data.xlsx');
  };
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  } else {
    return (
      <AccountsvipContainer style={{ marginTop: "1rem" }}>
        <CenterGroup>
          <Select
            value={currentMonthnow}
            onChange={(e) => handleMonthChange(e)}
            name=""
            id=""
          >
            {monthNames.map((month, i) => {
              return (
                <option key={`${month}`} value={i + 1}>
                  {month}
                </option>
              );
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </CenterGroup>
        <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S.No
                  </th>
                  <th className="t-head" scope="col">
                    Staff Name
                  </th>
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Salary
                  </th>
                  <th className="t-head" scope="col">
                    Amount Paid
                  </th>
                  <th className="t-head" scope="col">
                    Amount Pending
                  </th>
                  <th className="t-head" scope="col">
                    Actions
                  </th>
                  <th className="t-head" scope="col">
                    View Stats
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {filteredData?.map((user, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{user.user.userName}</td>
                      <td>{user.user.email}</td>
                      <td>{user.user.transactionDetail.salary}</td>
                      <td>{user.sumMoney}</td>
                      <td>
                        {user.user.transactionDetail.salary - user.sumMoney}
                      </td>
                      <td>
                      <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                           handleOpen(user);
                          }}
                        >
                         Action
                        </div>
                      </td>
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewStatsContainer(user);
                          }}
                        >
                          View Stats
                        </div>
                      </td>
                      <td>
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "yellow",
                            border: "1px solid yellow",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Pending
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
        {show && (
          <form
            onSubmit={(e) => submitTransaction(e, user.user.userId)}
            id="otp-container"
          >
            <span style={{ position: "absolute", right: "10px", top: "5px" }}>
              <Icon
                onClick={() => setShow(false)}
                style={{ color: "var(--icon-color)" }}
                icon={cross}
              ></Icon>
            </span>
            <input type="number" name="amount" placeholder="Amount" />
            <input type="text" name="description" placeholder="Decription" />
            <FormControlLabel
              control={
                <input
                  type="radio"
                  name="paymentStatus"
                  value="paid"
                  color="success"
                />
              }
              label="Paid"
            />
            <FormControlLabel
              control={
                <input
                  type="radio"
                  name="paymentStatus"
                  value="cancelled"
                  color="error"
                />
              }
              label="Cancelled"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                marginTop: "1.5rem",
                paddingBottom: "1.rem",
                display: "block",
                width: "100%",
              }}
            >
              Submit
            </Button>
          </form>
        )}
      </AccountsvipContainer>
    );
  }
}

function AccountsStudents({ filterData }) {


  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let [paymentData, setPaymentData] = useState({
    amount: 0,
    description: "",
    paymentStatus: "",
    date: Date.now(),
  });
  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Initialize state
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[currentMonthIndex]
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleMonthChange = (event) => {
    setSelectedMonth(monthNames[event.target.value - 1]);
    setMonth(parseInt(event.target.value));
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const month = new Date().getMonth();
  const [currentPage, setCurrentPage] = useState(1);
  const getTotalPages = (Coursedata) => {
    return Math.ceil(Coursedata?.length / itemsPerPage);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setCurrentPage(value);
  };
  const [itemsPerPage] = useState(50);
  const [otpContainer, setOtpContainer] = useState(false);

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  let [currentMonthnow, setMonth] = useState(currentMonth);
  const [open, setOpen] = React.useState(false);

  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});

  const handleOpen = (user) => {
    setUser(user);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setUser({});
  };

  let [userData, setAllUserData] = useState([]);

  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };
  const filterSalaryDetails = (user, currentMonthnow) => {
    const formattedDate =
      currentYear.toString() + "-" + "0" + currentMonthnow.toString();

    //console.log(students)
    // const currentMonthTransaction = user.transactionsRecieved.filter(
    //   (transaction) =>
    //   {
    //      return (
    //        new Date(transaction.date).getMonth() + 1 === currentMonthnow &&
    //        data.referenceId !== "" // Check if referenceId is not empty
    //      );
    //     }
    // );

    // console.log(currentMonthTransaction, "currentSalaryTransaction");
    // let sumData = calculateTotalAmount(currentMonthTransaction);
    return {
      user: user,
      sumMoney: 0,
      payments: [],
    };
  };
  const fetchHos = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/allstudent`, header);
      setVipData(response.allStudents);
      let students = response.allStudents.filter((transaction) => {
        return (
          new Date(transaction.createdAt).getMonth() + 1 === currentMonthnow &&
          new Date(transaction.createdAt).getFullYear() ===
            parseInt(selectedYear) &&
          transaction.referenceId !== "" // Check if referenceId is not empty
        );
      });
      console.log("user", students);
      let users = await students.map((user) => {
        return filterSalaryDetails(user, currentMonthnow);
      });

      setAllUserData(users);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };

  const submitTransaction = async (e, userId) => {
    e.preventDefault();
  
    // Get the amount from the form input and convert it to a float
    const amount = parseFloat(e.target["amount"].value);
    
    // Find the student by userId
    const student = userData.find(student => student.user._id === userId); // Assuming userData holds the current student data
    const courseFee = student.user.courseDetail.courseName.courseFee; // Get the course fee
    const totalPaid = calculateTotalAmount(student.user.transactionsRecieved || []); // Calculate total paid amount
    const pendingAmount = CashAmountByPercentage(courseFee, student.user.courseDetail.courseName.companyCommissionPercentage) - totalPaid; // Calculate pending amount
    
    // Check if the payment exceeds the pending amount
    if (amount > pendingAmount) {
      enqueueSnackbar("Payment amount exceeds the pending amount.", { variant: "error" });
      return; // Exit the function if validation fails
    }
  
    // Prepare headers for the request
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
  
    // Prepare transaction data for the request
    const transactionData = {
      amount: amount, // Use the parsed amount
      description: e.target["description"].value,
      paymentStatus: e.target["paymentStatus"].value,
      month: currentMonthnow,
    };
  
    try {
      // Make the POST request to submit the transaction
      const response = await postData(
        `${URL}/accounts/transaction/student/${userId}`,
        transactionData,
        header
      );
      
      // Reload the page or handle success as needed
      window.location.reload();
      console.log("Transaction successfully added:", response.data);
      // Handle success by resetting the form, showing a message, etc.
    } catch (error) {
      console.error("Error adding transaction:", error.response.data);
      // Handle the error accordingly
    }
  };
  

  // If using Snackbar for notifications

const generateAndDownloadPDF = (student) => {
  const studentName = student.user?.personalInfo?.studentName || 'N/A';
  const courseName = student.user?.courseDetail?.courseName?.courseName || 'N/A';
  const courseFee = parseFloat(student.user?.courseDetail?.courseName?.courseFee) || 0;
  const companyCommissionPercentage = parseFloat(student.user?.courseDetail?.courseName?.companyCommissionPercentage) || 0;
  const companyRevenue = ((courseFee * companyCommissionPercentage) / 100).toFixed(2);

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${currentDate.getFullYear()}`;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const transactions = (student.user?.transactionsRecieved || []).map((transaction, index) => {
    const date = formatDate(transaction.date) || 'N/A';
    const amount = parseFloat(transaction.amount) || 0;
    const status = transaction.paymentStatus;
    return [index + 1, date, amount.toFixed(2), status];
  });

  if (transactions.length === 0) {
    enqueueSnackbar('No payments available', { variant: 'warning' });
    return;
  }

  const totalTransactionsAmount = transactions.reduce((sum, transaction) => sum + parseFloat(transaction[2]), 0).toFixed(2);
  const doc = new jsPDF();

  doc.setFontSize(8);

  const addTextWithPageCheck = (text, x, y) => {
    if (y > 280) {
      doc.addPage();
      y = 20;
    }
    doc.text(text, x, y);
    return y + 10;
  };

  const logoWidth =35; // Width of the logo
  const logoHeight =35; // Height of the logo

  // Add logo to the right side
  doc.addImage(logo, 'PNG', 20,2, logoWidth, logoHeight); // Adjust Y position to center vertically with text

  let yPos = 10;
  yPos = addTextWithPageCheck("TALENT INITIATORS AND ACCELERATORS INDIA PRIVATE LIMITED", 75, yPos);
  yPos = addTextWithPageCheck("51/1, P&T Colony, Chettipalayam Road, Podanur, Coimbatore-641023", 75, yPos);
  yPos = addTextWithPageCheck("Mobile: 7373100400. Email: tiaedu.in@gmail.com, Website: WWW.tiaedu.in",75, yPos);

  // Generate two random 3-digit numbers
  const firstPart = Math.floor(100 + Math.random() * 900);
  const secondPart = Math.floor(100 + Math.random() * 900);

 // Add Invoice number with a slash between the two parts
 doc.text(`Invoice No.: ${firstPart}/${secondPart}`, 150, yPos+10);
 yPos = addTextWithPageCheck(`Date: ${new Date().toLocaleDateString('en-GB')}`, 150, yPos+20);

 

  yPos = addTextWithPageCheck(`Name : ${studentName}`, 20, yPos-20);
  yPos = addTextWithPageCheck(`Course: ${courseName}`, 20, yPos );
  yPos = addTextWithPageCheck(`Course Fee: ${courseFee}`, 20, yPos );
  yPos = addTextWithPageCheck(`Company Revenue: ${companyRevenue}`, 20, yPos );

  doc.setFont("helvetica", "bold"); // Set to bold
doc.setFontSize(14); // Set the desired font size (e.g., 16)

// Add the "STATEMENT" text
doc.text(`STATEMENT`, 90, yPos);

// Optionally, reset font size and style for the rest of the document
doc.setFont("helvetica", "normal"); // Reset to normal font
doc.setFontSize(8); 

  doc.autoTable({
    startY: yPos + 10,
    head: [['S.No.', 'Date', 'Amount', 'Status']],
    body: transactions,
    styles: { fontSize: 8 },
  });


  yPos = doc.lastAutoTable.finalY + 30;
  yPos = addTextWithPageCheck("Authorized Signatory", 150, yPos);
  yPos = addTextWithPageCheck("For more information about TIA and our services, please visit our website:www.tiaedu.in", 50, yPos + 20);

  doc.save('student_invoice.pdf');
};

  const CashAmountByPercentage = (amount, percentage) => {
    const vipCashAmount = (amount * percentage) / 100;
    return vipCashAmount;
  };
  const refereshTranscation = async (
    vipData,
    currentMonthnow,
    selectedYear
  ) => {
    let students = vipData.filter((transaction) => {
      return (
        new Date(transaction.createdAt).getMonth() + 1 === currentMonthnow &&
        new Date(transaction.createdAt).getFullYear() ===
          parseInt(selectedYear) &&
        transaction.referenceId !== "" // Check if referenceId is not empty
      );
    });
    let users = await students.map((user) => {
      return filterSalaryDetails(user, currentMonthnow);
    });
    setAllUserData(users);
  };
  // Filter userData based on filterData prop (search)
  const filteredData = userData.filter((student) => {
    const studentName = student.user.personalInfo.studentName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const refferedBy = student.user.referenceName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const branchJoined = student.user.courseDetail?.branchName?.branchName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const ConceptJoined = student.user.courseDetail.conceptName.conceptName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const CourseJoined = student.user.courseDetail.courseName.courseName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    return (
      studentName || refferedBy || branchJoined || ConceptJoined || CourseJoined
    );
  });
  useEffect(() => {
    if (vipData) {
      refereshTranscation(vipData, currentMonthnow, selectedYear);
    }
  }, [currentMonthnow, selectedYear]);
  useEffect(() => {
    fetchHos();
  }, []);
  console.log(viewStats);
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData.map(student => ({
      'S.no': student.user.personalInfo.studentName, // Adjust this according to your data structure
      'Student Name': student.user.personalInfo.studentName,
      'Referred By': student.user.referenceName,
      'Branch Joined': student.user.courseDetail?.branchName?.branchName,
      'Concept Joined': student.user.courseDetail.conceptName.conceptName,
      'Course Joined': student.user.courseDetail.courseName.courseName,
      'Course Fee': student.user.courseDetail.courseName.courseFee,
      'Company Revenue': CashAmountByPercentage(student.user.courseDetail.courseName.courseFee, student.user.courseDetail.courseName.companyCommissionPercentage),
      'Amount Paid': calculateTotalAmount(student.user.transactionsRecieved || []),
      'Amount Pending': CashAmountByPercentage(student.user.courseDetail.courseName.courseFee, student.user.courseDetail.courseName.companyCommissionPercentage) - calculateTotalAmount(student.user.transactionsRecieved || []),
    })));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Accounts Data');
    
    // Generate Excel file
    XLSX.writeFile(wb, 'accounts_data.xlsx');
  };
  const paginatedData = userData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  if (viewStats) {
    return (
      <ViewstatsStudent
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  } else {
    return (
      <AccountsvipContainer style={{ marginTop: "1rem" }}>
        <CenterGroup>
          <Select
            value={currentMonthnow}
            onChange={(e) => handleMonthChange(e)}
            name=""
            id=""
          >
            {monthNames.map((month, i) => {
              return (
                <option key={`${month}`} value={i + 1}>
                  {month}
                </option>
              );
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </CenterGroup>
        <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S.No
                  </th>
                  <th className="t-head" scope="col">
                    Student Name
                  </th>
                  <th className="t-head" scope="col">
                    Reffered By
                  </th>
                  <th className="t-head" scope="col">
                    Branch Joined
                  </th>
                  <th className="t-head" scope="col">
                    Concept Joined
                  </th>
                  <th className="t-head" scope="col">
                    Course Joined
                  </th>
                  <th className="t-head" scope="col">
                    Course Fee
                  </th>
                  <th className="t-head" scope="col">
                    Company Revenue
                  </th>
                  <th className="t-head" scope="col">
                    Amount Paid
                  </th>
                  <th className="t-head" scope="col">
                    Amount Pending
                  </th>
                  <th className="t-head" scope="col">
                    Action
                  </th>
                  <th className="t-head" scope="col">
                    View Stats
                  </th>

                  <th className="t-head" scope="col">
                    Payment Status
                  </th>
                  <th className="t-head" scope="col">
                    Download receipt
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {paginatedData?.map((student, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{student.user.personalInfo.studentName}</td>
                      <td>{student.user.referenceName}</td>
                      <td>
                        {student.user.courseDetail?.branchName?.branchName}
                      </td>
                      <td>
                        {student.user.courseDetail.conceptName.conceptName}
                      </td>
                      <td>{student.user.courseDetail.courseName.courseName}</td>

                      <td>{student.user.courseDetail.courseName.courseFee}</td>
                      <td>
                        {CashAmountByPercentage(
                          student.user.courseDetail.courseName.courseFee,
                          student.user.courseDetail.courseName
                            .companyCommissionPercentage
                        )}
                      </td>
                      {student.user.transactionsRecieved ? (
                        <>
                          <td>
                            {calculateTotalAmount(
                              student.user.transactionsRecieved
                            )}
                          </td>
                          <td>
                            {CashAmountByPercentage(
                              student.user.courseDetail.courseName.courseFee,
                              student.user.courseDetail.courseName
                                .companyCommissionPercentage
                            ) -
                              calculateTotalAmount(
                                student.user.transactionsRecieved
                              )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>0</td>
                          <td>
                            {CashAmountByPercentage(
                              student.user.courseDetail.courseName.courseFee,
                              student.user.courseDetail.courseName
                                .companyCommissionPercentage
                            )}
                          </td>
                        </>
                      )}

                      <td>
                      <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                           handleOpen(student.user);
                          }}
                        >
                         Action
                        </div>
                      </td>
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewStatsContainer(student.user);
                          }}
                        >
                          View Stats
                        </div>
                      </td>
                      <td>
                      {CashAmountByPercentage(
                        student.user.courseDetail.courseName.courseFee,
                        student.user.courseDetail.courseName.companyCommissionPercentage
                      ) - calculateTotalAmount(student.user.transactionsRecieved) === 0 ? (
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Paid
                        </span>
                      ) : (
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "yellow",
                            border: "1px solid yellow",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Pending
                        </span>
                      )}
                    </td>
                                  
                  <td>
                    <DownloadingIcon
                      onClick={() => generateAndDownloadPDF(student)}
                      sx={{
                        color: '#f00d88',
                        fontSize: '1.6rem',
                      }}
                    />
                  </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
       {show && (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      const paymentStatus = document.querySelector('input[name="paymentStatus"]:checked');
      if (!paymentStatus) {
        enqueueSnackbar("Please select a payment status.", { variant: "warning" });
        return;
      }
      submitTransaction(e, user._id);
    }}
    id="otp-container"
    style={{
      width: '90%',
      maxWidth: '500px',
      margin: '0 auto',
      padding: '1rem',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#25272d', // Dark background
    }}
  >
    <span style={{ position: "absolute", right: "10px", top: "5px" }}>
      <Icon
        onClick={() => setShow(false)}
        style={{ color: "var(--icon-color)", cursor: "pointer" }}
        icon={cross}
      />
    </span>
    <input
      type="number"
      name="amount"
      placeholder="Amount"
      className="custom-input"
      style={{
        width: '60%',
        padding: '10px',
        marginBottom: '1rem',
        borderRadius: '4px',
        border: '1px solid #ddd',
      }}
      step="0.01"
      required
    />
    <select
      name="description"
      className="custom-dropdown"
      style={{
        width: '60%',
        padding: '10px',
        marginBottom: '1rem',
        borderRadius: '4px',
        border: '1px solid #ddd',
      }}
      defaultValue=""
      required
    >
      <option value="" disabled hidden>Select a description</option>
      <option value="Success">Success</option>
                  <option value="Wrong Entry">Wrong Entry</option>
                  <option value="Wrong Payment">Wrong Payment</option>
                  <option value="Other">Other</option>
    </select>
    <Box display="flex" flexDirection="column" gap="10px" mt={2}>
      <FormControlLabel
        control={
          <input
            type="radio"
            name="paymentStatus"
            value="paid"
            style={{ accentColor: "green", marginRight: "8px" }}
          />
        }
        label="Paid"
        sx={{
          display: "flex",
          alignItems: "center",
          '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
        }}
      />
      <FormControlLabel
        control={
          <input
            type="radio"
            name="paymentStatus"
            value="cancelled"
            style={{ accentColor: "red", marginRight: "8px" }}
          />
        }
        label="Cancelled"
        sx={{
          display: "flex",
          alignItems: "center",
          '& .MuiFormControlLabel-label': { color: 'white', fontWeight: '500' },
        }}
      />
    </Box>
    <Button
      variant="outlined"
      type="submit"
      style={{
        width: '30%',
        marginTop: "1.5rem",
        borderColor: "#f00d88",
        color: "#f00d88",
      }}
    >
      Submit
    </Button>
  </form>
)}

      </AccountsvipContainer>
    );
  }
}
function ViewstatsStudent({ viewStats, setViewStatsContainer }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getTotalPages = (data) => {
    return Math.ceil(data.length / itemsPerPage);
  };

  const paginatedData = viewStats.transactionsRecieved.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const Paginations = styled(Pagination)`
    color: white; // Set text color to white
    & .MuiPaginationItem-root {
      color: white; // Ensure pagination items have white text
    }
    & .MuiPaginationItem-root.Mui-selected {
      background-color: #333; // Optionally, set background for selected item
      color: white; // Ensure selected pagination item has white text
    }
  `;
  const ViewstatsContainer = styled.div`
    .mt-3 {
      margin-top: 1rem !important;
    }
    .scroll {
      overflow-y: scroll;
      height: 100%;
    }
    .scroll::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    .scroll::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background-color: transparent;
    }
    .scroll::-webkit-scrollbar-button {
      height: 16px;
    }
    .t-head {
      text-align: center;
    }
    td {
      text-align: center;
    }
    #table-container {
      background-color: #25272d;
      margin-top: 1rem;
      padding: 2rem;
      border-radius: 10px;
      height: 70vh;
      overflow-y: scroll;
    }
    .t-head {
      color: white !important;
      background-color: #18171b !important;
      padding: 1rem !important;
      text-align: center;
    }
    .rounded-corners {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
    }
    .rounded-corners th,
    .rounded-corners td {
      border: 1px solid black;
    }
    thead tr {
      border-bottom: 1rem solid #25272d;
    }
    tr {
      border-color: #25272d;
    }
    .table > :not(caption) > * > * {
      background-color: #25272d;
    }
    td {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .btn-group label.btn {
      font-size: 0.7rem !important; /* Decrease font size inside buttons */
      padding: 0.5rem 0.5rem !important; /* Adjust button padding */
    }
    .btn-check:checked + .btn {
      background-color: var(--navbar-dark-primary);
      color: white;
      border-color: var(--navbar-dark-primary);
    }
    .btn-check:checked + .btn.red {
      background-color: red; /* Red color */
    }
    .btn-check:checked + .btn.warning {
      background-color: orange; /* Warning color (orange) */
    }
    .btn-check:checked + .btn.green {
      background-color: green; /* Green color */
    }
  `;

  return (
    <ViewstatsContainer style={{ marginTop: "1rem" }}>
      <Button
        variant="outlined"
        onClick={() => setViewStatsContainer(false)}
        sx={{
          borderColor: "white",
          color: "white",
          ":hover": {
            borderColor: "#f00d88",
            backgroundColor: "#f00d88",
            color: "White",
          },
          borderWidth: 2,
          borderRadius: 1,
          padding: "8px 16px",
        }}
      >
        Back
      </Button>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                S.No
                </th>
                <th className="t-head" scope="col">
                  Description
                </th>
                <th className="t-head" scope="col">
                  Amount
                </th>
                <th className="t-head" scope="col">
                  Received Time
                </th>
                <th className="t-head" scope="col">
                  Received Date
                </th>
                <th className="t-head" scope="col">
                  Payment Status
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {paginatedData.map((trans, i) => {
                return (
                  <tr key={i}>
                    <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                    <td>{trans.description}</td>
                    <td>{trans.amount}</td>
                    <td>{format(trans.date, "hh:mm:ss a")}</td>
                    <td>{format(trans.date, "dd-MM-yyyy")}</td>
                                        <td
                      style={{
                        padding: '2px 8px',        // Reducing padding for the box
                        fontSize: '12px',          // Adjusting font size
                        lineHeight: '0.2',         // Line height adjustment
                        marginTop: '10px',         // Margin above the box
                        borderRadius: '4px',       // Corner radius for box
                        width: '100px',            // Box width
                        textAlign: 'center',       // Centering text
                        display: 'inline-block',   // Keeping inline display
                        border: `1px solid ${
                          trans.paymentStatus === "paid" ? 'green' : 'red'
                        }`,                         // Border color based on status
                        color: trans.paymentStatus === "paid" ? 'green' : 'red',  // Text color
                        backgroundColor: 'transparent',  // Transparent background to simulate the outline style
                      }}
                    >
                      <span>
                        {trans.paymentStatus.toUpperCase()}
                      </span>
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
        <Paginations
          count={getTotalPages(viewStats.transactionsRecieved)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="secondary"
        />
      </Stack>
    </ViewstatsContainer>
  );
}

function Viewstats({ viewStats, setViewStatsContainer }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items to display per page

  const totalItems = viewStats?.payments?.length || 0;
  const getTotalPages = () => Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return viewStats?.payments?.slice(startIndex, endIndex);
  };

  const ViewstatsContainer = styled.div`
  .download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
    margin-top: 1rem;
    .mt-3 {
      margin-top: 1rem !important;
    }
    .scroll {
      overflow-y: scroll;
      height: 100%;
    }
    .scroll::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    .scroll::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background-color: transparent;
    }
    .scroll::-webkit-scrollbar-button {
      height: 16px;
    }
    .t-head {
      text-align: center;
    }
    td {
      text-align: center;
    }
    #table-container {
      background-color: #25272d;
      margin-top: 3rem;
      padding: 2rem;
      border-radius: 10px;
      height: 80vh;
      overflow-y: scroll;
    }
    .t-head {
      color: white !important;
      background-color: #18171b !important;
      padding: 1rem !important;
      text-align: center;
    }
    .rounded-corners {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
    }
    .rounded-corners th,
    .rounded-corners td {
      border: 1px solid black;
    }
    thead tr {
      border-bottom: 1rem solid #25272d;
    }
    tr {
      border-color: #25272d;
    }
    .table > :not(caption) > * > * {
      background-color: #25272d;
    }
    td {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .btn-group label.btn {
      font-size: 0.7rem !important;
      padding: 0.5rem 0.5rem !important;
    }
    .btn-check:checked + .btn {
      background-color: var(--navbar-dark-primary);
      color: white;
      border-color: var(--navbar-dark-primary);
    }
    .btn-check:checked + .btn.red {
      background-color: red; /* Red color */
    }
    .btn-check:checked + .btn.warning {
      background-color: orange; /* Warning color (orange) */
    }
    .btn-check:checked + .btn.green {
      background-color: green; /* Green color */
    }
  `;

  return (
    <ViewstatsContainer>
      <Button
        variant="outlined"
        onClick={() => setViewStatsContainer(false)}
        sx={{
          borderColor: "white", // Customize border color
          color: "white", // Customize text color
          ":hover": {
            borderColor: "#f00d88", // Customize border color on hover
            backgroundColor: "#f00d88", // Customize background color on hover
            color: "white", // Customize text color on hover
          },
          borderWidth: 2, // Customize border width
          borderRadius: 1, // Customize border radius
          padding: "8px 16px", // Customize padding
        }}
      >
        Back
      </Button>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                S.No
                </th>
                <th className="t-head" scope="col">
                  Amount
                </th>
                <th className="t-head" scope="col">
                  Description
                </th>
                <th className="t-head" scope="col">
                  Received Time
                </th>
                <th className="t-head" scope="col">
                  Received Date
                </th>
                <th className="t-head" scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {getPaginatedData()?.map((transcation, i) => {
                return (
                  <tr key={i}>
                    <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                    <td>{transcation.amount}</td>
                    <td>{transcation.description}</td>
                    <td>{format(transcation.date, "hh:mm:ss a")}</td>
                    <td>{format(transcation.date, "dd-MM-yyyy")}</td>
                      <td
                      style={{
                        padding: '2px 8px',        // Reducing padding for the box
                        fontSize: '12px',          // Adjusting font size
                        lineHeight: '0.2',         // Line height adjustment
                        marginTop: '10px',         // Margin above the box
                        borderRadius: '4px',       // Corner radius for box
                        width: '100px',            // Box width
                        textAlign: 'center',       // Centering text
                        display: 'inline-block',   // Keeping inline display
                        border: `1px solid ${
                          transcation.paymentStatus === "paid" ? 'green' : 'red'
                        }`,                         // Border color based on status
                        color: transcation.paymentStatus === "paid" ? 'green' : 'red',  // Text color
                        backgroundColor: 'transparent',  // Transparent background to simulate the outline style
                      }}
                    >
                      <span>
                        {transcation.paymentStatus.toUpperCase()}
                      </span>
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Stack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
        <Paginations
          count={getTotalPages()}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="secondary"
        />
      </Stack>
    </ViewstatsContainer>
  );
}

function VipSystem() {
  const month = new Date().getMonth();

  const { userRole, userToken } = useGlobalContext();
  const [vipSystemData, setVipSystemData] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Get the current month index and year
  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(
    monthNames[new Date().getMonth()]
  ); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // Function to format the month and year as "MM-YYYY"

  const formatMonthYear = (monthIndex, year) => {
    const month = String(monthIndex + 1).padStart(2, "0"); // Ensure two digits for month
    return `${month}-${year}`;
  };
  const [formattedDate, setFormattedDate] = useState(
    formatMonthYear(currentMonthIndex, currentYear)
  );
  
  // Handle month change
  const handleMonthChange = (e) => {
    const newMonthIndex = monthNames.indexOf(e.target.value); // Get the index of the selected month
    setSelectedMonth(newMonthIndex);
    const formattedDate = formatMonthYear(newMonthIndex, selectedYear);
    setFormattedDate(formattedDate);
  };

  // Handle year change
  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    const formattedDate = formatMonthYear(selectedMonth, newYear);
    setFormattedDate(formattedDate);
  };
  const fetchVipSystemData = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${URL}/accounts/vipsystem`, header);
      
      setVipSystemData(response.data.monthData);
    
    } catch (error) {}
  };
    console.log(vipSystemData[formattedDate]);
  useEffect(() => {
    (async () => {
      await fetchVipSystemData();
    })();
  }, []);
  const downloadExcel = () => {
    const dataToExport = vipSystemData[formattedDate]?.map((data, i) => ({
      'S.no': i + 1,
      'Vip Name': data.vipName,
      'Status': data.vipStatus,
      'Region': data.regionType,
      'Region Name': data.regionName,
      "Region's Collection": data.regionCollection,
      'Vip Collection': data.vipCollection,
      'Bonus eligibility': 'Yes', // Replace with actual data if necessary
      'No.of Shares': data.shares,
      'Additional Revenue': data.commission,
    })) || [];

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'VIP System Data');
    XLSX.writeFile(wb, `VIP_System_Data_${formattedDate}.xlsx`);
  };
  return (
    <ViewIncomeContainer>
      <CenterGroup>
        {/* Month Selector */}
        <Select value={selectedMonth} onChange={handleMonthChange}>
          {monthNames.map((month, i) => (
            <option key={i} value={month}>
              {month}
            </option>
          ))}
        </Select>

        {/* Year Selector */}
        <Select value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
      </CenterGroup>
      <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={vipSystemData[formattedDate]?.length===0}
          >
            Download Excel
          </Button>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.no
                </th>
                <th className="t-head" scope="col">
                  Vip Name
                </th>
                <th className="t-head" scope="col">
                  Status
                </th>
                <th className="t-head" scope="col">
                  Region
                </th>
                <th className="t-head" scope="col">
                  Region Name
                </th>
                <th className="t-head" scope="col">
                  Region's Collection
                </th>
                <th className="t-head" scope="col">
                  Vip Collection
                </th>
                <th className="t-head" scope="col">
                  Bonus eligibility
                </th>
                <th className="t-head" scope="col">
                  No.of Shares
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  Additional Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {vipSystemData[formattedDate]?.map((data,i)=>{
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{data.vipName}</td>
                    <td>
                      {" "}
                      <span
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          padding: "0.6rem",
                          textAlign: "center",
                        }}
                      >
                        {data.vipStatus}
                      </span>
                    </td>
                    <td>{data.regionType}</td>
                    <td>{data.regionName}</td>
                    <td>{data.regionCollection}</td>
                    <td>{data.vipCollection}</td>
                    <td>
                      <span
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "green",
                          border: "1px solid green",
                          padding: "0.6rem",
                          textAlign: "center",
                        }}
                      >
                        Yes
                      </span>
                    </td>
                    <td>{data.shares}</td>
                    <td>{data.commission}</td>
                  </tr>
                );
              })}
              
            </tbody>
          </table>
        </div>
      </div>
    </ViewIncomeContainer>
  );
}

const AccountsvipContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }
.custom-input::placeholder {
  color: black; /* Change this to your preferred color */
  opacity: 1;  /* Ensure full opacity */
}
  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }/* Base styles */
#otp-container {
  padding: 1rem;
  position: absolute;
  top: 30%;
  left: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #25272d;
  height: 20rem;
  width: 20rem;
  border: 2px solid white;
  border-radius: 10px;
}
.otp-input {
  color: black;
  border-radius: 8px;
}
#otp ::placeholder {
  color: black;
}

/* Responsive styles */
@media (max-width: 888px) {
  #otp-container {
    top: 20%;
    left:50%;
    transform: translateX(-50%);
    width: 50%;
    height: auto;
    padding: 1.5rem;
  }
  .otp-input {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  #otp-container {
    top: 10%;
    width: 45%;
    padding: 1rem;
  }
  .otp-input {
    font-size: 0.85rem;
  }
}

@media (max-width: 400px) {
  #otp-container {
    width:40%;
    padding: 0.8rem;
  }
  .otp-input {
    font-size: 0.8rem;
  }
}

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .btn-group label.btn {
    font-size: 0.7rem !important; /* Decrease font size inside buttons */
    padding: 0.5rem 0.5rem !important; /* Adjust button padding */
  }
  .btn-check:checked + .btn {
    background-color: var(--navbar-dark-primary);
    color: white;
    border-color: var(--navbar-dark-primary);
  }
  .btn-check:checked + .btn.red {
    background-color: red; /* Red color */
  }
  .btn-check:checked + .btn.warning {
    background-color: orange; /* Warning color (orange) */
  }
  .btn-check:checked + .btn.green {
    background-color: green; /* Green color */
  }
`;

const CenterGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%; /* Ensure the group takes full width */
`;

const Select = styled.select`
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #36283a;
  color: white;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
    margin: 0 3px;
  }
`;
const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const ViewIncomeContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  margin-top: 1rem;
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 80vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .btn-group label.btn {
    font-size: 0.7rem !important; /* Decrease font size inside buttons */
    padding: 0.5rem 0.5rem !important; /* Adjust button padding */
  }
  .btn-check:checked + .btn {
    background-color: var(--navbar-dark-primary);
    color: white;
    border-color: var(--navbar-dark-primary);
  }
  .btn-check:checked + .btn.red {
    background-color: red; /* Red color */
  }
  .btn-check:checked + .btn.warning {
    background-color: orange; /* Warning color (orange) */
  }
  .btn-check:checked + .btn.green {
    background-color: green; /* Green color */
  }
`;

export {
  Accountsvip,
  AccountsVipFranchise,
  AccountsFranchise,
  AccountsHOstaff,
  AccountsStudents,
  VipSystem,
};
