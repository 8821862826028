
import axios from "axios";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Icon } from "react-icons-kit";
import { bin2 } from "react-icons-kit/icomoon/bin2";
import { pencil } from "react-icons-kit/icomoon/pencil";
import { enqueueSnackbar } from "notistack";
import { URL } from "../../../assets/mocData/config";
import { deleteData, fetchData } from "../../../Api";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import EditConcept from "../EditForms/EditConcept";
import * as XLSX from "xlsx";
import {
  Pagination,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ic_search } from "react-icons-kit/md/ic_search";
import CircularProgress from "@mui/material/CircularProgress";

function ViewConcept({ concepts, setConcept, setFilterData }) {
  const { userRole, userToken, setConceptData } = useGlobalContext();
  const [editContainer, setEditContainer] = useState(false);
  const [editData, setEditData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false); // for confirmation modal
  const [selectedConcept, setSelectedConcept] = useState(null); // store concept to delete
  const downloadExcel = () => {
    const dataToExport = concepts.map((element, index) => ({
      "S no": index + 1,
      "Concept Code": element.conceptCode,
      "Concept Name": element.conceptName,
      Status: element.status === "active" ? "Active" : "Inactive",
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Concepts");

    // Generate an Excel file and trigger download
    XLSX.writeFile(workbook, "Concepts.xlsx");
  };
  const fetchConcepts = async () => {
    let header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      setIsLoading(true);
      let response = await fetchData(`${URL}/${userRole}/concept`, header);
      setConceptData(response);
      setConcept(response);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteDialog = (conceptId) => {
    setSelectedConcept(conceptId); // set the concept to be deleted
    setDialogOpen(true); // open confirmation modal
  };
  const getPagedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    if (concepts) {
      return concepts?.slice(startIndex, startIndex + itemsPerPage);
    }
    return [];
  };
  const handleDeleteConfirm = async () => {
    if (selectedConcept) {
      let header = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        let response = await deleteData(
          `${URL}/${userRole}/concept/${selectedConcept}`,
          header
        );
        enqueueSnackbar("Deleted Successfully", {
          autoHideDuration: 1000,
          variant: "success",
        });
        if (response) {
          const filteredArray = concepts.filter(
            (obj) => obj._id !== selectedConcept
          );
          setConceptData(filteredArray);
          setConcept(filteredArray);
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: "error",
        });
      } finally {
        setDialogOpen(false); // close the confirmation modal
      }
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedConcept(null); // reset selected concept
  };

  useEffect(() => {
    fetchConcepts();
  }, []);

  const [dataFilt, setFiltData] = useState("");
  const itemsPerPage =50;
  const [currentPage, setCurrentPage] = useState(1);
  const getTotalPages = (data) => {
    return Math.ceil((data || []).length / itemsPerPage);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
 if (isLoading) {
   return (
     <div
       style={{
         width: "100%",
         height: "80%",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 10,
         backgroundColor: "#18171b",
       }}
     >
       <CircularProgress color="secondary" />
     </div>
   );
 }
  return (
    <ViewConceptContainer>
      <div id="Concept-table-container">
        {editContainer ? (
          <EditConcept
            userRole={userRole}
            userToken={userToken}
            editData={editData}
            setEditData={setEditData}
            setEditContainer={setEditContainer}
            concepts={concepts}
            setConcept={setConcept}
          />
        ) : (
          <> 
       <Button variant="outlined" 
       sx={{
        borderRadius: '10px',
        marginLeft: '1rem',
       // Customize color as needed
       color:"#f00d88",
       borderColor:"#f00d88",
      }}
       className="download-btn"
       disabled={getPagedData()?.length === 0}
       onClick={downloadExcel}>Download as excel</Button>
            <div id="table-container">
              <div className="scroll">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="t-head rounded-start-3" scope="col">
                      S No
                      </th>
                      <th className="t-head" scope="col">
                        Concept Code
                      </th>
                      <th className="t-head" scope="col">
                        Concept Name
                      </th>
                      <th className="t-head" scope="col">
                        Status
                      </th>
                      {userRole === "admin" && (
                        <th className="t-head rounded-end-3" scope="col">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-dark">
                    {getPagedData()?.map((element, i) => (
                      <tr key={element._id}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>{element.conceptCode}</td>
                        <td>{element.conceptName}</td>
                        <td>
                          {element.status === "active" ? (
                            <span
                              className="badge"
                              style={{
                                height: "80%",
                                width: "100%",
                                color: "#31c127",
                                border: "1px solid #31c127",
                                padding: "0.6rem",
                                textAlign: "center",
                              }}
                            >
                              Active
                            </span>
                          ) : (
                            <span
                              className="badge"
                              style={{
                                height: "80%",
                                width: "100%",
                                color: "Red",
                                border: "1px solid Red",
                                padding: "0.6rem",
                                textAlign: "center",
                              }}
                            >
                              Inactive
                            </span>
                          )}
                        </td>
                        {userRole === "admin" && (
                          <td>
                            <span
                              onClick={() => openDeleteDialog(element._id)}
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                            >
                              <Icon icon={bin2} />
                            </span>
                            <span
                              onClick={() => {
                                setEditData(element);
                                setEditContainer(true);
                              }}
                            >
                              <Icon icon={pencil} />
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Stack
              alignItems={"center"}
              marginTop={"0.5rem"}
              marginBottom={"1rem"}
            >
              <Paginations
                count={getTotalPages(concepts)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                color="secondary"
              />
            </Stack>
          </>
        )}
      </div>

      {/* Confirmation Modal */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this concept?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ViewConceptContainer>
  );
}

const Paginations = styled(Pagination)`
  color: white;
  & .MuiPaginationItem-root {
    color: white;
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333;
    color: white;
  }
`;
const ViewConceptContainer = styled.div`
  h2,
  p,
  span,
  h6 {
    color: white;
  }
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  hr {
    border: 1.4px solid black;
  }

  .container {
    margin-top: 1rem;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;
export default ViewConcept;
