import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import TopContainer from '../miniComponents/subcomponents/TopContainer';
import AddVipForm from '../miniComponents/AddForms/AddVipForm';
import ViewVipTable from '../miniComponents/Tables/ViewVipTable';
import { ViewVipReferalTable } from '../miniComponents/Tables/ViewVipReferalTable';
import { Box, Tabs, Tab ,CircularProgress} from "@mui/material";

function VipContainer({ user, courseListTitles }) {
    const [filter, setFilter] = useState(false);
    const [selectedView, setSelectedView] = useState('ViewVip');
    const [addVipData, setAddVipData] = useState([]);
    const [filterData, setFilterData] = useState(""); // Holds the search query
    const [vipData, setVipData] = useState([]); // Full VIP data
    const [filteredVipData, setFilteredVipData] = useState([]); // Search filtered data
    const [loading, setLoading] = useState(false);
    const handleChange = (event, newValue) => {
     
        setSelectedView(newValue);
        
    };

    const FilterOpen = () => {
        filter ? setFilter(false) : setFilter(true);
    };

    useEffect(() => {
      const filteredData = vipData.filter(vip => 
          (vip.userName?.toLowerCase().includes(filterData.toLowerCase()) || "") ||
          (vip.user_id?.toLowerCase().includes(filterData.toLowerCase()) || "") ||
          (vip.otherDetails?.vip_status?.toLowerCase().includes(filterData.toLowerCase()) || "") ||
          (vip.email?.toLowerCase().includes(filterData.toLowerCase()) || "") ||
          (vip.phoneNumber?.toLowerCase().includes(filterData.toLowerCase()) || "")
      );
      setFilteredVipData(filteredData);
  }, [filterData, vipData]);
  
    const showFields = {
        search: selectedView !== 'AddVip',
        filter: selectedView !== 'AddVip',
    };

    return (
        <VipTableContainer>
            <div id="course-list" style={{ height: "100vh", overflowY: "scroll" }}>
            {loading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: "#f00d88" }} />
          </LoadingOverlay>
        )}
                <TopContainer
                    heading={"VIP DETAILS"}
                    options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
                    filter={filter}
                    FilterOpen={FilterOpen}
                    setFilterData={setFilterData} // Setting search query in filterData
                    showFields={showFields}
                />
                
                <Box
                    sx={{
                        marginTop: '1rem',
                        width: '100%',
                        backgroundColor: 'var(--navbar-dark-primary)',
                        borderRadius: '8px',
                        boxShadow: 3,
                        padding: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Tabs
                        value={selectedView}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="VIP Navigation Tabs"
                        textColor="inherit"
                        indicatorColor="secondary"
                        sx={{
                            '& .MuiTab-root': {
                                minWidth: '150px',
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                color: 'white',
                                '&.Mui-selected': {
                                    color: 'white',
                                },
                                margin: '0 0.5rem',
                            },
                            '& .MuiTabs-indicator': {
                                height: '4px',
                                backgroundColor: '#f00d88',
                            },
                        }}
                    >
                        {(user === 'admin' || user === 'hos') && (
                            <Tab label="Vip Member" value="ViewVip" />
                        )}
                        <Tab label="Vip Referrals" value="vipReferrals" />
                        <Tab label="Add Vip" value="AddVip" />
                    </Tabs>
                </Box>

                {selectedView === "vipStatus" ? (
                    <>{/* <StatusTable /> */}</>
                ) : selectedView === "ViewVip" ? (
                    <ViewVipTable
                        filterData={filterData} // Passing search query
                        setFilterData={setFilterData}
                        vipData={filteredVipData} // Passing filtered VIP data to the table
                        setVipData={setVipData} // Allowing the table to fetch VIP data
                    />
                ) : selectedView === "vipReferrals" ? (
                    <ViewVipReferalTable
                        filterData={filterData}
                        setFilterData={setFilterData}
                        setAddVipData={setAddVipData}
                        setSelectedView={setSelectedView}
                    />
                ) : selectedView === "AddVip" ? (
                    <AddVipForm addVipData={addVipData} />
                ) : null}
            </div>
        </VipTableContainer>
    );
}

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const VipTableContainer = styled.div`
    width: 78vw;
  @media(min-width:800px)and (max-width:1000px) {
     width:100vw;
}
  #top-cont {
    width: 100%;
   
  }
  #course-list {
    width: 100%;
    padding: 2rem;
     position: relative;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
    #course-list{
      width: 100vw;
    }
  }
  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
`;
export default VipContainer