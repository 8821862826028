import React, { useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import { Box, Typography, Button, LinearProgress } from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

function TemplatePagesWaitForApproval() {
  useEffect(() => {
    // Redirect to tiaedu.in after 5 seconds
    const timer = setTimeout(() => {
      window.location.href = "https://tiaedu.in";
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);

  return (
    <Wrapper>
      <Content>
        <IconWrapper>
          <AnimatedIcon />
        </IconWrapper>
        <Typography variant="h4" component="h1">
          Please Wait for Our Approval
        </Typography>
        <AnimatedProgress />
        <Message variant="body1">
          Your application is under review. You will receive an email
          notification once your details have been processed.
        </Message>
        <StyledButton variant="contained" size="large">
          Contact Support
        </StyledButton>
      </Content>
    </Wrapper>
  );
}

// Keyframes for animations
const pulse = keyframes`
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(2);
  }
`;

const scaleUp = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

// Styled Components with animations
const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background);
`;

const Content = styled(Box)`
  text-align: center;
  background: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const IconWrapper = styled(Box)`
  margin-bottom: 1em;
  color: #ff9800;
`;

const AnimatedIcon = styled(HourglassEmptyIcon)`
  font-size: 60px;
  animation: ${pulse} 1.5s infinite;
`;

const AnimatedProgress = styled(LinearProgress)`
  margin-top: 1em;
  margin-bottom: 2em;
  height: 10px;
  border-radius: 5px;
  animation: ${scaleUp} 2s infinite alternate;
`;

const Message = styled(Typography)`
  margin-top: 1em;
  margin-bottom: 2em;
  color: #555;
`;

const StyledButton = styled(Button)`
  margin-top: 1em;
  color: white;
  background-color: #ff9800;
  transition: transform 0.2s;

  &:hover {
    background-color: #e68900;
    transform: scale(1.05);
  } 
`;

export default TemplatePagesWaitForApproval;
