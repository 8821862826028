import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { enqueueSnackbar } from "notistack";
import { useGlobalContext } from "../assets/contex/GlobalContext";
import { fetchData } from "../Api";
import TopContainer from "./miniComponents/subcomponents/TopContainer";
import { URL as url } from "../assets/mocData/config";
import {
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ButtonGroup,
} from "@mui/material";
import { cross } from "react-icons-kit/icomoon/cross";
import { Pagination, Stack } from "@mui/material";
import { Tabs, Tab, Box } from "@mui/material";
// Adjust the import path according to your project structure
const Paginations = styled(Pagination)`
 color: white; // Set text color to white
 & .MuiPaginationItem-root {
   color: white; // Ensure pagination items have white text
 }
 & .MuiPaginationItem-root.Mui-selected {
   background-color: #333; // Optionally, set background for selected item
   color: white; // Ensure selected pagination item has white text
  }
`;
const Container = styled.div`
  width: 100%;
  padding: 2rem;
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  h2 {
    padding-left: 1rem;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }
  .accordion-button::after {
    content: none !important;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }
  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }
  h5 {
    text-align: center;
    color: white;
  }

  #check-box {
    position: absolute;
  }

  #check-box .checkbox-bx {
    position: absolute;
    right: 5px;
    top: 2px;
  }
  #check-box .checkbox-bx label {
    position: relative;
    height: 24px;
    width: 24px;
  }
  #check-box .checkbox-bx input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  #check-box .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: #25272d;
  }

  #check-box .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5sec, opacity 0.5sec;
  }

  #check-box .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }

  .pay-btn {
    width: 10rem;
    background-color: #25272d;
    color: greenyellow;
    border-radius: 10px;
    padding: 0.5rem;
    border: 1px solid greenyellow;
  }

  input {
    color: white;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }
  .accordion-button::after {
    content: none !important;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }
  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }
  h5 {
    text-align: center;
    color: white;
  }

  #check-box {
    position: absolute;
  }

  #check-box .checkbox-bx {
    position: absolute;
    right: 5px;
    top: 2px;
  }
  #check-box .checkbox-bx label {
    position: relative;
    height: 24px;
    width: 24px;
  }
  #check-box .checkbox-bx input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  #check-box .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: #25272d;
  }

  #check-box .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5sec, opacity 0.5sec;
  }

  #check-box .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }

  #pay-btn {
    width: 10rem;
    background-color: #25272d;
    color: greenyellow;
    border-radius: 10px;
    padding: 0.5rem;
    border: 1px solid greenyellow;
  }

  input {
    color: white;
  }
  .vip {
    margin-bottom: 10px;
  }

  .vip button {
    display: block;
    width: 100%; /* Full width for responsiveness within nested box */
    padding: 8px;
    border: 1px solid #2e2e2e; /* Border color for inner box buttons */
    background-color: black; /* Background color for inner box buttons */
    color: rgb(150, 146, 146); /* White text color */
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  }

  .vip button:hover {
    background-color: #d70491; /* Pink glow on hover */
  }

  select {
    color: rgb(123, 116, 116);
    background-color: #25272d;
    border: 1px solid black;
    padding: 0.75rem;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  #miniNav {
    display: none;
  }
`;
const ReferedVipStyled = styled.div`
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 80vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .btn-group label.btn {
    font-size: 0.7rem !important; /* Decrease font size inside buttons */
    padding: 0.5rem 0.5rem !important; /* Adjust button padding */
  }
  .btn-check:checked + .btn {
    background-color: var(--navbar-dark-primary);
    color: white;
    border-color: var(--navbar-dark-primary);
  }
  .btn-check:checked + .btn.red {
    background-color: red; /* Red color */
  }
  .btn-check:checked + .btn.warning {
    background-color: orange; /* Warning color (orange) */
  }
  .btn-check:checked + .btn.green {
    background-color: green; /* Green color */
  }
`;
function VipRevenueSection() {
  const [selectedView, setSelectedView] = useState("Vip");
  const [filter, setFilter] = useState(false);
  const [vipData, setVipData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [value,setValues]=useState()
  const { userRole, userToken, profileData } = useGlobalContext();
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedView(newValue);
  };
  const fetchSettings = async () => {
    const headers = {
      userRole: "admin",
      userToken: userToken,
    };
    try {
      let data = await fetchData(`${url}/admin/settings`, headers);
      console.log(data);
      setValues(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);
  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(
        `${url}/vip/revenue/vip/${profileData.profileData._id}`,
        header
      );
    
      setVipData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVip();
  }, []);

  return (
    <Container style={{ height: "100vh", overflowY: "scroll" }}>
      <TopContainer
        heading={"REVENUE DETAILS"}
        options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
        filter={filter}
        FilterOpen={FilterOpen}
        setFilterData={setFilterData}
        showFields={{ search: true }}
      />
      <Box
        sx={{
          marginTop: "1rem",
          width: "100%",
          backgroundColor: "var(--navbar-dark-primary)", // Light gray background
          borderRadius: "8px", // Rounded corners
          boxShadow: 3, // Shadow effect
          padding: "1rem",
          marginBottom: "1rem",
          display: "flex", // Use Flexbox
          justifyContent: "center", // Center the tabs
        }}
      >
        <Tabs
          value={selectedView}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Revenue Navigation Tabs"
          textColor="inherit"
          indicatorColor="secondary"
          sx={{
            "& .MuiTab-root": {
              minWidth: "120px",
              fontWeight: "bold",
              fontSize: "0.875rem",
              color: "white",
              "&.Mui-selected": {
                color: "white",
              },
            },
            "& .MuiTabs-indicator": {
              height: "4px",
              backgroundColor: "#f00d88",
            },
          }}
        >
          <Tab label="Vip" value="Vip" />
          <Tab label="Vip Franchise" value="vipFranchise" />
          <Tab label="Franchise" value="Franchise" />
          <Tab label="Students" value="Students" />
        </Tabs>
      </Box>

      <div>
        {selectedView === "Vip" && (
          <VipRevenue filterData={filterData} value={value} />
        )}
        {selectedView === "vipFranchise" && (
          <VipFranchiseRevenue filterData={filterData} value={value} />
        )}
        {selectedView === "Franchise" && (
          <FranchiseRevenue filterData={filterData} value={value} />
        )}
        {selectedView === "Students" && (
          <StudentsRevenue filterData={filterData} />
        )}
      </div>
    </Container>
  );
}
function VipFranchiseRevenue({filterData,value}) {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  let [user, setUser] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  let [userData, setUserData] = useState([]);
  
  const paginatedData = vipData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
    console.log(amount);
  };

  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(
        `${url}/vip/revenue/vipfranchise/${profileData.profileData._id}`,
        header
      );
    
      setVipData(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const filteredData = vipData.filter((user) => {
    const UserName = user?.userName?.toLowerCase().includes(filterData.toLowerCase()); // Provide a fallback if userName is undefined
    return UserName;
  });

  useEffect(() => {
    fetchVip();
  }, []);
console.log(value)
  return (
    <ReferedVipStyled>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.No
                </th>
                <th className="t-head" scope="col">
                  VIP Franchise Name
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  My Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {" "}
              {paginatedData?.map((user, i) => {
                const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                return (
                  <tr key={i}>
                    <td>{continuousIndex}</td>
                    <td>{user.userName}</td>
                    <td>{value?.vipFranchiseReferralRevenue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}

function FranchiseRevenue({filterData,value}) {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  let [user, setUser] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  let [userData, setUserData] = useState([]);

  const paginatedData = vipData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
    console.log(amount);
  };

  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(
        `${url}/vip/revenue/franchise/${profileData.profileData._id}`,
        header
      );
     
      setVipData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVip();
  }, []);
  const filteredData = vipData.filter((user) => {
    const UserName = user?.userName?.toLowerCase().includes(filterData.toLowerCase()); // Provide a fallback if userName is undefined
    return UserName;
  });
  return (
    <ReferedVipStyled>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.No
                </th>
                <th className="t-head" scope="col">
                  Franchise Name
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  My Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
            {paginatedData?.map((user, i) => {
                const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                return (
                  <tr>
                    <td>{continuousIndex}</td>
                    <td>{user.userName}</td>
                    <td>{value?.franchiseReferralRevenue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}
function VipRevenue({filterData,value}) {
  
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  let [user, setUser] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  let [userData, setUserData] = useState([]);

  const paginatedData = vipData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
    console.log(amount);
  };

  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(
        `${url}/vip/revenue/vip/${profileData.profileData._id}`,
        header
      );
     
      setVipData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVip();
  }, []);
  const filteredData = vipData.filter((user) => {
    const UserName = user?.userName?.toLowerCase().includes(filterData.toLowerCase()); // Provide a fallback if userName is undefined
    return UserName;
  });
  return (
    <ReferedVipStyled>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.No
                </th>
                <th className="t-head" scope="col">
                  VIP Name
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  My Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
            {paginatedData?.map((user, i) => {
                const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                return (
                  <tr>
                    <td>{continuousIndex}</td>
                    <td>{user.userName}</td>
                    <td>{value?.vipReferralCommission}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}
function StudentsRevenue({filterData}) {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  let [user, setUser] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  let [userData, setUserData] = useState([]);

  const paginatedData = vipData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
  
  };
  const CashAmountByPercentage = (amount, percentage) => {
    const vipCashAmount = (amount * percentage) / 100;
    return vipCashAmount;
  };
  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(
        `${url}/vip/revenue/student/${profileData.profileData._id}`,
        header
      );
     
      setVipData(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVip();
  }, []);
  const filteredData = vipData.filter((user) => {
    const UserName = user.personalInfo.studentName.toLowerCase().includes(filterData.toLowerCase());
    const conceptName = user.courseDetail.conceptName.conceptName.toLowerCase().includes(filterData.toLowerCase());
    const courseName = user.courseDetail.courseName.courseName.toLowerCase().includes(filterData.toLowerCase());
    return UserName ||conceptName ||courseName;
  });
  return (
    <ReferedVipStyled>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.No
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>
                <th className="t-head" scope="col">
                  Concept Name
                </th>
                <th className="t-head" scope="col">
                  Course Name
                </th>
                <th className="t-head" scope="col">
                  Course Fee
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  My Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
            {paginatedData?.map((user, i) => {
                const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                return (
                  <tr>
                    <td>{continuousIndex}</td>
                    <td>{user.personalInfo.studentName}</td>
                    <td>{user.courseDetail.conceptName.conceptName}</td>
                    <td>{user.courseDetail.courseName.courseName}</td>
                    <td>{user.courseDetail.courseName.courseFee}</td>
                    <td>
                      {CashAmountByPercentage(
                        user.courseDetail.courseName.courseFee,
                        user.courseDetail.courseName.vipPercentage
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}
export {
  FranchiseRevenue,
  VipRevenue,
  StudentsRevenue,
  VipRevenueSection,
  VipFranchiseRevenue,
};
