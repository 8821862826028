import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
function CurrentStudyingStudent() {
  let [userData, setUserData] = useState([]);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();
  const navigate = useNavigate();
  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    if (userRole == "vip") {
      try {
        let response = await fetchData(
          `${url}/studying/${profileData.profileData._id}`,
          header
        );
        setUserData(response);
        console.log(response);
      } catch (error) {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    } else if (userRole == "franchise") {
      try {
        let response = await fetchData(
          `${url}/students/studying/franchise/${profileData.profileData.email}`,
          header
        );
        setUserData(response);
        console.log(response);
      } catch (error) {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    }
  };
  useEffect(() => {
    fetchFranchise();
  }, []);
  return (
    <Container>
      <div id="viewFranchise-table-container">
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                   S No
                  </th>
                  <th className="t-head" scope="col">
                    Name
                  </th>
                  <th className="t-head" scope="col">
                    Age
                  </th>
                  <th className="t-head" scope="col">
                    PhoneNumber
                  </th>
                  <th className="t-head" scope="col">
                    WhatsappNumber
                  </th>
                  <th className="t-head" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {userData?.map((user, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{user.personalInfo.studentName}</td>
                      <td>{user.personalInfo.age}</td>
                      <td>{user.personalInfo.phoneNumber}</td>
                      <td>{user.personalInfo.phoneNumber}</td>
                      <td>{user.Status}</td>
                      <td>{user.streetName}</td>
                      <td>
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setProfileViewData(user);
                            navigate("/viewvipprofile");
                          }}
                        >
                          View Profile
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
}
const Container = styled.div`
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 80vh;

    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;
export default CurrentStudyingStudent;
