import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { Button } from "@mui/material";

function ViewVipTable({ filterData, setFilterData, vipData, setVipData }) {
  const [referedUserData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(50);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();
  const navigate = useNavigate();
  const fetchVIP = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${url}/vip/`, header);
      setUserData(response.data); // Setting fetched data
      setVipData(response.data); // Also updating parent state
      console.log(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  };

  useEffect(() => {
    fetchVIP();
  }, []);

  // Pagination logic
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = vipData.slice(indexOfFirstRow, indexOfLastRow); // Use vipData for rows

  const getTotalPages = () => {
    return Math.ceil(vipData.length / rowsPerPage);
  };
  const handleDownloadExcel = () => {
    const formattedData = vipData.map((data, index) => {
      const firstBusinessDate = data.otherDetails.firstBusinessDate;
      const vipDayCount = firstBusinessDate
        ? Math.floor(
            (new Date() - new Date(firstBusinessDate)) / (1000 * 60 * 60 * 24)
          )
        : "Not yet started";
  
        return {
          "S.No": index + 1,
          "VIP ID": data.user_id,
          "VIP Name": data.userName,
          "VIP Collection": data.totalCollection, // Replace with actual collection value if available
          "Email": data.email,
          "Phone Number": data.phoneNumber,
          "First Business Date": data.otherDetails.firstBusinessDate
            ? new Date(data.otherDetails.firstBusinessDate.$date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "Not available",
          "VIP Day Count": vipDayCount, // New column
          "VIP Status": data.otherDetails.vip_status, // Replace with actual status if available
          "User Status": data.status,
          "Age": data.age,
          "Bank Account Name": data.bankAccountName,
          "Bank Account Number": data.bankAccountNumber,
          "Bank Branch Name": data.bankBranchName,
          "Blood Group": data.bloodGroup,
          "Country": data.country,
          "Date of Birth": data.dateOfBirth
            ? new Date(data.dateOfBirth.$date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "Not available",
          "Education Details": data.educationDetails.map((edu) => `${edu.degree} - ${edu.collegeName}`).join(", "),
          "District": data.district,
          "Door/Flat Number": data.doorFlatNumber,
          "Facebook": data.facebook,
          "Father's Name": data.fatherName,
          "Gender": data.gender,
          "IFSC Code": data.ifscCode,
          "Instagram": data.instagram,
          "Job Description": data.jobDescription.map((job) => `${job.jobDescription} at ${job.companyName}`).join(", "),
          "Joined Date": data.joinedDate
            ? new Date(data.joinedDate.$date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "Not available",
          "Landmark": data.landMark,
          "Mother's Name": data.motherName,
          "PAN Card Number": data.panCardNumber,
          "Whatsapp Number": data.whatsappNumber,
          "Pin Code": data.pinCode,
          "State": data.state,
          "Street Name": data.streetName,
          "Verified": data.verified,
          "Image Access Key": data.imageACCESSKEY,
          "Working Option": data.workingOption.length > 0 ? data.workingOption.join(", ") : "Not available",
          "Created At": data.createdAt
            ? new Date(data.createdAt.$date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "Not available",
          "Updated At": data.updatedAt
            ? new Date(data.updatedAt.$date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "Not available",
        };        
    });
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VIP Data");
  
    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
  
    // Create a link to download the file
    const url = window.URL.createObjectURL(dataBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "vip_data.xlsx"); // Specify file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  


  return (
    <ViewvipContainer>
      <div id="viewvip-table-container">
        <Button
          variant="outlined"
          className="download-btn"
          onClick={handleDownloadExcel} // Call your download function here
          sx={{
            borderRadius: "10px",
            marginLeft: "1rem",
            // Customize color as needed
            color: "#f00d88",
            borderColor: "#f00d88",
          }}
          disabled={currentRows.length === 0}
        >
          Download Excel
        </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
            <thead>
  <tr>
    <th className="t-head rounded-start-3" scope="col">S No</th>
    <th className="t-head" scope="col">VIP ID</th>
    <th className="t-head" scope="col">VIP Name</th>
    <th className="t-head" scope="col">VIP Collection</th>
    <th className="t-head" scope="col">Email</th>
    <th className="t-head" scope="col">Phone Number</th>
    <th className="t-head" scope="col">First Business Date</th>
    <th className="t-head" scope="col">VIP Day Count</th> {/* New column header */}
    <th className="t-head" scope="col">VIP Status</th>
    <th className="t-head" scope="col">User Status</th>
    <th className="t-head rounded-end-3" scope="col">Actions</th>
  </tr>
</thead>

<tbody className="table-dark">
  {currentRows.length === 0 ? (
    <tr>
      <td
        colSpan="10" // Updated colSpan to 10 to match the new column
        style={{ textAlign: "center", padding: "2rem" }}
      >
        <SentimentVeryDissatisfiedIcon fontSize="large" />
        <p>No Records found</p>
      </td>
    </tr>
  ) : (
    currentRows.map((data, i) => {
      const firstBusinessDate = data.otherDetails.firstBusinessDate;
      const vipDayCount = firstBusinessDate
        ? Math.floor(
            (new Date() - new Date(firstBusinessDate)) / (1000 * 60 * 60 * 24)
          )
        : "Not yet started";

      return (
        <tr key={data.user_id}>
          <td>{indexOfFirstRow + i + 1}</td>
          <td>{data.user_id}</td>
          <td>{data.userName}</td>
          <td>{data.totalCollection}</td>
          <td>{data.email}</td>
          <td>{data.phoneNumber}</td>
          <td>
            {firstBusinessDate
              ? new Date(firstBusinessDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })
              : "Not available"}
          </td>
          <td>{vipDayCount}</td> {/* New column data */}
          <td>
            <span
              style={{
                display: "inline-block",
                padding: "5px 10px",
                borderRadius: "5px",
                color: "white",
                border: `1px solid white`,
                fontSize: "14px",
                textAlign: "center",
                width: "auto",
                minWidth: "80px",
                maxWidth: "100%",
              }}
            >
              {data.otherDetails.vip_status}
            </span>
          </td>
          <td>
            <span
              style={{
                display: "inline-block",
                padding: "5px 10px",
                borderRadius: "5px",
                color: data.status === "active" ? "green" : "red",
                border: `2px solid ${data.status === "active" ? "green" : "red"}`,
                fontSize: "14px",
                textAlign: "center",
                width: "auto",
                minWidth: "80px",
                maxWidth: "100%",
              }}
            >
              {data.status}
            </span>
          </td>
          <td>
            <button
              className="badge"
              style={{
                height: "80%",
                width: "100%",
                color: "white",
                border: "1px solid white",
                padding: "0.6rem",
                textAlign: "center",
                background: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                setProfileViewData(data);
                navigate("/profile");
              }}
            >
              View Profile
            </button>
          </td>
        </tr>
      );
    })
  )}
</tbody>

            </table>
          </div>
        </div>
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={getTotalPages()}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ViewvipContainer>
  );
}


const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const ViewvipContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;

    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default ViewVipTable;
