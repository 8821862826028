import React from 'react'
import Concept from "../../../assets/images/Concept.png"
import Course from "../../../assets/images/Course.png";
import Branch from "../../../assets/images/Branches.png";
import User from "../../../assets/images/user.png";
import Vip from "../../../assets/images/vip.png"
import Franchise from "../../../assets/images/Franches.png"
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
function DashBoardSecond({ dashboardDetail }) {
  const head = dashboardDetail.head;
  console.log(head)
  let { profileData ,userRole} = useGlobalContext();
  const navigate = useNavigate();
  if (head) {
    if(userRole=="vipFranchise"){
      return (
        <div class="container pt-3">
          <div class="row justify-content-around">
            <div class="col col-md-5 col-lg-4 mb-2 ">
              <div
                className="container-mini"

              >
                <div>
                  <img className="img-ctn-icon" src={Course} alt="" />
                </div>
                <div class="content-inside">
                  <h6>Courses</h6>
                  <h1>{head?.courseCount}</h1>
                </div>
              </div>
            </div>
            <div class="col col-md-5 col-lg-4 mb-2 ">
              <div
                className="container-mini"
              >
                <div>
                  <img className="img-ctn-icon" src={Branch} alt="" />
                </div>
                <div class="content-inside">
                  <h6>Branches</h6>
                  <h1>150</h1>
                </div>
              </div>
            </div>
            <div class="col col-md-5 col-lg-4 mb-2">
              <div
                className="container-mini"
              >
                <div>
                  <img className="img-ctn-icon" src={User} alt="" />
                </div>
                <div class="content-inside">
                  <h6>Pincode Referal Students</h6>
                  <h1>{head?.pinCodeReferal}</h1>
                </div>
              </div>
            </div>
            <div class="col col-md-5 col-lg-4 mb-2 ">
              <div
                className="container-mini"
              >
                <div>
                  <img className="img-ctn-icon" src={User} alt="" />
                </div>
                <div class="content-inside">
                  <h6>Pincode Studying Students</h6>
                  <h1>{head?.pinCodeStudying}</h1>
                </div>
              </div>
            </div>
            <div class="col col-md-5 col-lg-4 mb-2 ">
              <div
                className="container-mini"
              >
                <div>
                  <img className="img-ctn-icon" src={User} alt="" />
                </div>
                <div class="content-inside">
                  <h6> Pincode Students Completed</h6>
                  <h1>{head?.pinCodeCompleted}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
     else if (profileData.role == "admin" || profileData.role == "hos") {
       return (
         <div class="container pt-3">
           <div class="row justify-content-around">
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
                 
               >
                 <div>
                   <img className="img-ctn-icon" src={Concept} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Concepts</h6>
                   <h1>{head.conceptCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
                 
               >
                 <div>
                   <img className="img-ctn-icon" src={Course} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Courses</h6>
                   <h1>{head.courseCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"

                 >
                 <div>
                   <img className="img-ctn-icon" src={Branch} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branches</h6>
                   <h1>{head.branchCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branch Staff</h6>
                   <h1>{head.branchStaff}</h1>
                 </div>
               </div>
             </div>

             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
                
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>HO Staff</h6>
                   <h1>{head.hos}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Vip} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>VIP Members </h6>
                   <h1>{head.vipMember}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Vip} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>VIP Franchise Member </h6>
                   <h1>{head.vipFranchise}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Franchise} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Franchise Member </h6>
                   <h1>{head.franchise}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Vip} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Vip Referrals</h6>
                   <h1>{head.vipRefer}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Franchise} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Franchise Referrals</h6>
                   <h1>{head.franchiseRefered}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Vip} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>VIP Franchise Referrals</h6>
                   <h1>{head.referVipFranchise}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <p>Student Admission</p>
                   <h1>{head.studentsStudying}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Student Referrals </h6>
                   <h1>{head.studentRefer}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Student Completed </h6>
                   <h1>{head.studentsCompleted}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div className="container-mini" >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Beginner VIP</h6>
                   <h1>{head.beginnerVip}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div className="container-mini" >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Startup VIP</h6>
                   <h1>
                     {" "}
                     <h1>{head.startupVip}</h1>
                   </h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
                 
               >
                <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>DLI</h6>
                   <h1>{head.dli}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
                 
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>SLI</h6>
                   <h1>{head.sli}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-3 mb-2 ">
               <div
                 className="container-mini"
                
               >
                 
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>NLI</h6>
                   <h1>{head.nli}</h1>
                 </div>
               </div>
             </div>
             
           </div>
         </div>
       );
     } else if (profileData.role == "franchise") {
       return (
         <div class="container pt-3">
           <div class="row justify-content-around">
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Course} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Courses</h6>
                   <h1>{head?.courseCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Branch} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branches</h6>
                   <h1>{head?.branchCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Student Completed</h6>
                   <h1>{head?.studentsCompleted}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Current Students</h6>
                   <h1>{head?.studentsStudying}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Students Reference</h6>
                   <h1>{head?.studentRefer}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branch Staff</h6>
                   <h1>{head?.branchStaffCount}</h1>
                 </div>
               </div>
             </div>
           </div>
         </div>
       );
     } else if (profileData.role == "branchstaff") {
       return (
         <div class="container pt-3">
           <div class="row justify-content-around">
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Course} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Courses</h6>
                   <h1>{head?.courseCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Branch} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branches</h6>
                   <h1>{head?.branchCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Student Completed</h6>
                   <h1>{head?.studentsCompleted}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Current Students</h6>
                   <h1>{head?.studentsStudying}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Students Reference</h6>
                   <h1>{head?.studentRefer}</h1>
                 </div>
               </div>
             </div>
           </div>
         </div>
       );
     } else if (profileData.role == "vip") {
       return (
         <div class="container pt-3">
           <div class="row justify-content-around">
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={Course} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Courses</h6>
                   <h1>{head?.courseCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
                //  onClick={() => navigate("/branches")}
               >
                 <div>
                   <img className="img-ctn-icon" src={Branch} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Branches</h6>
                   <h1>{head?.branchCount}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Student Completed</h6>
                   <h1>{head?.studentsCompleted}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Current Students</h6>
                   <h1>{head?.studentsStudying}</h1>
                 </div>
               </div>
             </div>
             <div class="col col-md-5 col-lg-4 mb-2 ">
               <div
                 className="container-mini"
               >
                 <div>
                   <img className="img-ctn-icon" src={User} alt="" />
                 </div>
                 <div class="content-inside">
                   <h6>Students Reference</h6>
                   <h1>{head?.studentRefer}</h1>
                 </div>
               </div>
             </div>
           </div>
         </div>
       );
     }
  }
 
}

export default DashBoardSecond


function DashCont1({ user, dashboardDetail }) {

  let { profileData } = useGlobalContext();

  const navigate = useNavigate();
  if (user == "admin"||user=="hos") {
    return (
        <></>
    //   <>
    

    //   </>
    );
  }
  // if(user=="vipFranchise"){
  //     return(
  //         <div class="container pt-3">
  //         <div class="row justify-content-around">
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/courselist')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Courses</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2">
  //             <div className='container-mini'  onClick={()=>navigate('/studentadmission')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon2} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Students</h6>
  //                     <h1>3</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2">
  //             <div className='container-mini'  onClick={()=>navigate('/branches')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon3} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Branches</h6>
  //                     <h1>50</h1>
  //                 </div>

  //             </div>
  //         </div>

  //         </div>

  //     </div>
  //     )
//   else if (user == "vip") {
//     return (
//       <div class="container pt-3">
//         <div class="row justify-content-around">
//           <div class="col col-md-5 col-lg-3 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/courselist")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Courses</h6>
//                 <h1>{dashboardDetail.courseCount}</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-3 mb-2">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/branches")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon3} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Branches</h6>
//                 <h1>{dashboardDetail.branchCount}</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-3 mb-2">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/studentadmission")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon2} alt="" />
//               </div>
//               <div class="content-inside">
//                 <p>Student Admission</p>
//                 <h1>3</h1>
//               </div>
//             </div>
//           </div>

//           <div class="col col-md-5 col-lg-3 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/studentreference")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon2} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Student Referrence </h6>
//                 <h1>10</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div class="col col-md-5 col-lg-3 mb-2">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/viprefrence")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon4} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Vip Reference</h6>
//                 <h1>1</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-3 mb-2">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/franchisereference")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon4} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Franchise Reference</h6>
//                 <h1>10</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }else if(user=="franchise"){
//     return (
//       <div class="container pt-3">
//         <div class="row justify-content-around">
//           <div class="col col-md-5 col-lg-4 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/courselist")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Courses</h6>
//                 <h1></h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-4 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/branches")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Branches</h6>
//                 <h1></h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-4 mb-2">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/studentcompleted")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon3} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Student Completed</h6>
//                 <h1>50</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-4 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/studentadmission")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Current Students</h6>
//                 <h1>262</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-4 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/studentreference")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Students Reference</h6>
//                 <h1>262</h1>
//               </div>
//             </div>
//           </div>
//           <div class="col col-md-5 col-lg-4 mb-2 ">
//             <div
//               className="container-mini"
//               onClick={() => navigate("/currentstudent")}
//             >
//               <div>
//                 <img className="img-ctn-icon" src={icon1} alt="" />
//               </div>
//               <div class="content-inside">
//                 <h6>Branch Staff</h6>
//                 <h1>262</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

  // }else if(user=="francise"){
  //     return(
  //         <div class="container pt-3">
  //         <div class="row justify-content-around">
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/courselist')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Courses</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/branches')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Branches</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2">
  //             <div className='container-mini'  onClick={()=>navigate('/studentcompleted')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon3} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Student Completed</h6>
  //                     <h1>50</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/studentadmission')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Current Students</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/studentreference')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Students Reference</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>
  //         <div class="col col-md-5 col-lg-4 mb-2 ">
  //             <div className='container-mini'  onClick={()=>navigate('/currentstudent')}  >
  //                 <div >
  //                     <img className='img-ctn-icon' src={icon1} alt=""/>
  //                 </div>
  //                 <div class="content-inside">
  //                     <h6>Branch Staff</h6>
  //                     <h1>262</h1>
  //                 </div>

  //             </div>
  //         </div>

  //         </div>

  //     </div>
  //     )
  // }else if(user=="branchstaff"){
  //     return (
  //         <div class="container pt-3">
  //                 <div class="row justify-content-around">
  //                 <div class="col col-md-5 col-lg-3 mb-2 ">
  //                     <div className='container-mini'  onClick={()=>navigate('/courselist')}  >
  //                         <div >
  //                             <img className='img-ctn-icon' src={icon1} alt=""/>
  //                         </div>
  //                         <div class="content-inside">
  //                             <h6>Courses</h6>
  //                             <h1>262</h1>
  //                         </div>

  //                     </div>
  //                 </div>
  //                 <div class="col col-md-5 col-lg-3 mb-2">
  //                     <div className='container-mini'  onClick={()=>navigate('/branches')}  >
  //                         <div >
  //                             <img className='img-ctn-icon' src={icon3} alt=""/>
  //                         </div>
  //                         <div class="content-inside">
  //                             <h6>Branches</h6>
  //                             <h1>50</h1>
  //                         </div>

  //                     </div>
  //                 </div>
  //                 <div class="col col-md-5 col-lg-3 mb-2">
  //                     <div className='container-mini'  onClick={()=>navigate('/studentadmission')}  >
  //                         <div >
  //                             <img className='img-ctn-icon' src={icon2} alt=""/>
  //                         </div>
  //                         <div class="content-inside">
  //                             <p>Student Admission</p>
  //                             <h1>3</h1>
  //                         </div>

  //                     </div>
  //                 </div>

  //                 <div class="col col-md-5 col-lg-3 mb-2 ">
  //                     <div className='container-mini'  onClick={()=>navigate('/studentreference')}  >
  //                         <div >
  //                             <img className='img-ctn-icon' src={icon2} alt=""/>
  //                         </div>
  //                         <div class="content-inside">
  //                             <h6>Student Referrence </h6>
  //                             <h1>10</h1>
  //                         </div>

  //                     </div>
  //                 </div>

  //                 </div>
  //                 <div className="row">
  //                 <div class="col col-md-5 col-lg-3 mb-2">
  //                     <div className='container-mini'  onClick={()=>navigate('/studentcompleted')}  >
  //                         <div >
  //                             <img className='img-ctn-icon' src={icon4} alt=""/>
  //                         </div>
  //                         <div class="content-inside">
  //                             <h6>Student Completed</h6>
  //                             <h1>10</h1>
  //                         </div>

  //                     </div>
  //                 </div>

  //                 </div>
  //             </div>
  //       )
  // }
}

