import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import AddFranchise from "../miniComponents/AddForms/AddFranchise";
import FranchiseReferralTable from "../miniComponents/Tables/FranchiseReferralTable";
import FranchiseTable from "../miniComponents/Tables/FranchiseTable";
import AddBranch from "../miniComponents/AddForms/AddBranch";
import BranchTable from "../miniComponents/Tables/BranchTable";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import { Tabs, Tab, Box ,CircularProgress} from "@mui/material";

function FranchiseContainer() {
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(""); // Search input state

  const { userRole } = useGlobalContext();

  const [franchiseData, setFranchise] = useState({});
  const [selectedView, setSelectedView] = useState("ViewFranchiseMember");

  const FilterOpen = () => {
    setFilter(!filter);
  };

  const [loading, setLoading] = useState(false);
  const handleChange = (event, newValue) => {
      setSelectedView(newValue);
  };


  return (
    <FranchiseTableContainer>
      <div id="course-list" style={{ height: "100vh", overflowY: "scroll" }}>
      {loading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: "#f00d88" }} />
          </LoadingOverlay>
        )}
        {/* Conditionally render TopContainer */}
        {["ViewFranchise", "ViewFranchiseMember", "FranchiseReferrals"].includes(selectedView) ? (
          <TopContainer
            heading={"FRANCHISE DETAILS"}
            options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
            filter={filter}
            FilterOpen={FilterOpen}
            setFilterData={setFilterData} // Pass setFilterData to update search input
            showFields={{ search: true, filter: true }} // Display search and filter fields
          />
        ) : (
          <TopContainer
            heading={"FRANCHISE DETAILS"}
            showFields={{ search: false, filter: false }} // Only display heading
          />
        )}

        <Box
          sx={{
            marginTop: "1rem",
            width: "100%",
            backgroundColor: "var(--navbar-dark-primary)",
            borderRadius: "8px",
            boxShadow: 3,
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedView}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Franchise Navigation Tabs"
            textColor="inherit"
            indicatorColor="secondary"
            sx={{
              "& .MuiTab-root": {
                minWidth: "120px",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "white",
                "&.Mui-selected": {
                  color: "white",
                },
              },
              "& .MuiTabs-indicator": {
                height: "4px",
                backgroundColor: "#f00d88",
              },
            }}
          >
            {userRole !== "hos" &&(
            <Tab label="ADD FRANCHISE" value="AddFranchise" />)}
            <Tab label="VIEW FRANCHISE" value="ViewFranchise" />
            <Tab label="ADD FRANCHISE OWNER" value="AddFranchiseMember" />
            <Tab label="VIEW FRANCHISE OWNER" value="ViewFranchiseMember" />
            <Tab label="FRANCHISE REFERRALS" value="FranchiseReferrals" />
          </Tabs>
        </Box>
        {selectedView === "ViewFranchiseMember" ? (
          <FranchiseTable filterData={filterData} setFilterData={setFilterData} />
        ) : selectedView === "FranchiseReferrals" ? (
          <FranchiseReferralTable filterData={filterData} setFilterData={setFilterData} setSelectedView={setSelectedView} setFranchise={setFranchise} />
        ) : selectedView === "AddFranchiseMember" ? (
          <AddFranchise setSelectedView={setSelectedView} franchiseData={franchiseData} />
        ) : selectedView === "AddFranchise" ? (
          <AddBranch />
        ) : selectedView === "ViewFranchise" ? (
          <>
            {filter ? (
              <div style={{ filter: "blur(15px)" }}>
                <BranchTable user={userRole} filterData={filterData} setFilterData={setFilterData} />
              </div>
            ) : (
              <BranchTable user={userRole} filterData={filterData} setFilterData={setFilterData} />
            )}
          </>
        ) : null}
      </div>
    </FranchiseTableContainer>
  );
}
const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const FranchiseTableContainer = styled.div`
  width: 100%;
  overflow: hidden;
  #course-list {
    width: 100%;
    padding: 1.3rem;
    position:relative;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
    
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
   
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
`;

export default FranchiseContainer;
