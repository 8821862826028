import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import { Pagination, Stack ,Button} from "@mui/material";
import * as XLSX from 'xlsx';

export default function FranchiseTable({filterData}) {
  let [data, setUserData] = useState([]);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();
  const navigate = useNavigate();
  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    userRole == "hos"
      ? (header.userRole = "admin")
      : (header.userRole = userRole);
    try {
      let response = await fetchData(`${url}/admin/franchise`, header);
      setUserData(response);
      console.log(response);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };
  console.log(data);
  useEffect(() => {
    fetchFranchise();
  }, []);
  const filteredData = data.filter((user) => {
    return (
      user.user_id.toLowerCase().includes(filterData.toLowerCase()) ||
      user.userName.toLowerCase().includes(filterData.toLowerCase()) ||
      user.workingOption?.join(", ").toLowerCase().includes(filterData.toLowerCase()) ||
      user.landMark.toLowerCase().includes(filterData.toLowerCase()) ||
      user.streetName.toLowerCase().includes(filterData.toLowerCase()) ||
      user.pinCode.toString().includes(filterData) ||
      user.email.toLowerCase().includes(filterData.toLowerCase()) ||
      user.phoneNumber.includes(filterData)
    );
  });


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Franchises");
    XLSX.writeFile(wb, "franchises.xlsx");
  };
  return (
    <ViewfranchiseContainer>
      <div id="viewFranchise-table-container">
      <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={currentData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S No
                  </th>
                  <th className="t-head" scope="col">
                    Franchise ID
                  </th>
                  <th className="t-head" scope="col">
                    Franchise Owner Name
                  </th>
                  <th className="t-head" scope="col">
                    No of Concepts
                  </th>
                  <th className="t-head" scope="col">
                    Concepts
                  </th>
                  <th className="t-head" scope="col">
                    Branch Area
                  </th>
                  <th className="t-head" scope="col">
                    Branch Address
                  </th>
                  <th className="t-head" scope="col">
                    Area Pincode
                  </th>
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    WhatsApp Number
                  </th>
                  <th className="t-head" scope="col">
                    User Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentData?.map((user, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{user.user_id}</td>
                      <td>{user.userName}</td>
                      <td>{user.workingOption?.length}</td>
                      <td>{user.workingOption}</td>
                      <td>{user.landMark}</td>
                      <td>{user.streetName}</td>
                      <td>{user.pinCode}</td>
                      <td>{user.email}</td>
                      <td>{user.phoneNumber}</td>
                      <td>{user.whatsappNumber}</td>
                      <td>
  <span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: user.status === "active" ? "green" : "red", // Text color matches the border color
      border: `2px solid ${user.status === "active" ? "green" : "red"}`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >
    {user.status}
  </span>
</td>

                      <td>
                        <span
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setProfileViewData(user);
                            navigate("/profile");
                          }}
                        >
                          View Profile
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
        <Paginations
  count={Math.ceil(data.length / itemsPerPage)}
  page={currentPage}
  onChange={handlePageChange}
  variant="outlined"
  color="secondary"
/>
  </Stack>
</div>
    </ViewfranchiseContainer>
  );
}

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`
const ViewfranchiseContainer = styled.div`
  .mt-3 {
    margin-top: 1rem !important;
  }
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }

  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }

  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    color: white;
  }

  th, td {
    text-align: center;
    padding: 1rem;
  }

  th {
    background-color: #18171b !important;
    color: white !important;
  }

  tbody tr {
    border-color: #25272d;
  }

  tbody tr:hover {
    background-color: #333; /* Set the hover background color */
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

