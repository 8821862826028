import React, { useEffect,useState } from 'react'
import { styled } from 'styled-components'
import { useParams } from 'react-router-dom';
import { postData,fetchData, putData ,deleteData} from '../../../Api';
import { URL } from '../../../assets/mocData/config';
import { useGlobalContext } from '../../../assets/contex/GlobalContext';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';  // Import useSnackbar
import { LocalConvenienceStoreOutlined, SettingsInputAntenna } from '@mui/icons-material';


function Crmleadprofile() {
    const [selectedView, setSelectedView] = useState('About');
    const {id} = useParams();
    const [status,setStatus]=useState("")
    const [massage,setMassage]=useState([])
    const [convo,setConvo]=useState("")
    const [details,setDetails]=useState([])
    const [state,setState]=useState(false)
    
    const [meetings,setMeetings]=useState([{schedulerType:"",
      schedulerName: "",
      scheduledDate: "",
      scheduledTime: "",
    meetingStatus:""}])

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [newDatas,setNewDatas]=useState({ 
      generalInformation:{
        followersId:"",
        followersName:"",
        source:""},
      personalInformation: {
      studentName: "",
      phoneNumber: "",
      age: "",
      bloodGroup: "",
      dataOfBirth:"",
      gender: "",
      fatherName: "",
      motherName: "",
      degree: "",
      leadStatus:""
  },address: {
    streetNo: "",
    streetName: "",
    landmark: "",
    district: "",
    state: "",
    pincode: ""
},messages:["hai.34"]})


    const { userToken, userRole,profileData } = useGlobalContext();

    const [formData, setFormData] = useState({
      schedulerType:newDatas.generalInformation.followersId,
      schedulerName: newDatas.generalInformation.followersName,
      scheduledDate: "",
      scheduledTime: ""
    })

    let headers = {
      userRole: userRole,
      userToken: userToken,
    };

    const { enqueueSnackbar } = useSnackbar();
    const handleClick = async () => {
      const data = { message: `${convo}.${new Date()}` };
    
      // Check if a conversation input is present
      if (!convo.length) {
        enqueueSnackbar("Please enter some text before updating.", { variant: "warning" });
        return;
      }
    
      try {
        // Update lead status only if a status is chosen
        if (status) {
          newDatas.personalInformation.leadStatus = status;
          await putData(`${URL}/leads/${id}`, newDatas, headers);
        }
    
        // Update the conversation if there's a message input
        if (convo.length) {
          await postData(`${URL}/leads/${id}`, data, headers);
          setConvo("");
        }
    
        // Show success notification after successful update
        enqueueSnackbar("Conversation updated successfully!", { variant: "success" });
    
        // Reset form state
        setState(!state);
        setStatus("");
        console.log(newDatas);
      } catch (error) {
        enqueueSnackbar("Failed to update conversation.", { variant: "error" });
        console.error(error);
      }
    };
    

    

   const getprofileData=async()=>{
    try {
      // Fetch data from the server
      const data = await fetchData(`${URL}/leads/${id}`, headers);
      
      // Log the entire data object to verify its structure
      console.log('Fetched data:', data);
  
      // Update state with fetched data 
      setNewDatas(data);

      setFormData({  
        schedulerType:data.generalInformation.followersId,
        schedulerName: data.generalInformation.followersName,
        scheduledDate: "",
        scheduledTime: ""})
  
      // Ensure data.messages is defined and log it
      if (data.messages) {
        console.log('Data messages:', data.messages);
        setMeetings(data.meeting)

        setMassage(data.messages); // or setMessage if it's the correct setter function
        

       
        console.log(meetings)
      } else {
        console.warn('No messages found in the fetched data.');
      }
  
      // Log a message to confirm the function execution
      console.log('State update attempted.');
      
    } catch (error) {
      // Handle and log any errors that occur during fetch
      console.error('Error fetching profile data:', error);
    }
    
   }

    useEffect(()=>{
      getprofileData()
     
    },[state])

    console.log(id)

    useState(()=>{

    },[])
    const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [id]: value
      }));

      console.log(formData)
    };
    
    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    const validateForm = () => {
      const { schedulerType, schedulerName, scheduledDate, scheduledTime } = formData;
      
      if (!schedulerType) {
        enqueueSnackbar('Scheduler Type is required', { variant: 'warning' });
        return false;
      }
    
      if (!schedulerName) {
        enqueueSnackbar('Scheduler Name is required', { variant: 'warning' });
        return false;
      }
    
      if (!scheduledDate) {
        enqueueSnackbar('Scheduled Date is required', { variant: 'warning' });
        return false;
      }
    
      if (!scheduledTime) {
        enqueueSnackbar('Scheduled Time is required', { variant: 'warning' });
        return false;
      }
    
      return true;
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
    
      if (validateForm()) {
        console.log('Form data:', formData);
        // Add your form submission logic here
      }
    };


  const handleMeetingDelete=async(nid)=>{
    try{

      await deleteData(`${URL}/leads/${id}/meetings/${nid}`,headers)
      window.location.reload();}
    catch(err){
      console.log(err)
    }

  }
  const handlemeetingactived=async(nid,body)=>{


    console.log(body)
    

   
    try{
      await putData(`${URL}/leads/${id}/meetings/${nid}/status`,body,headers)

      console.log("success")
      window.location.reload()

    }
    catch(err){

      console.log(err)

    }
  }



    const handlemeeting=async()=>{
      console.log(formData)
      if (!validateForm()) {
       return false;
      }
   try{
    await postData(`${URL}/leads/${id}/meetings`,formData,headers)
    window.location.reload();
    console.log("ll")
    setFormData({  
      schedulerType:formData.generalInformation.followersId,
      schedulerName: formData.generalInformation.followersName,
      scheduledDate: "",
      scheduledTime: ""})

     
   }
   catch(err){
    console.log(err)
   }


    }

    const formatDate=(dateString)=> {
      const date = new Date(dateString);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit', minute: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
    
  return (
    <Crmleadprofilecontainer style={{height:"88vh",overflowY:"scroll"}}>

       <div>
       
        <div  >
        <div class="accordion-item">
        <div  style={{height: 'auto',overflowY:'scroll',paddingTop:'1rem'}} className="scroll accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-header" style={{overflow:"hidden"}}>
            <div className="container pt-2 d-flex">
                <div className="col-1">
               
            </div>

           

              <div className="col-md-12 col-lg-12">
              <h3  style={{color:"white",marginLeft:"0px"}}>LEAD DETAILS</h3>
              <div class="container-main">
                
  <div class="container-main1">
    <div class="cont">
    <p class="label">Name</p>
    </div>
    <div class='cont1'>
      <h6>{newDatas.personalInformation.studentName}</h6>
    </div>
  </div>

  <div class="container-main2">
    <div class='cont'>
    <p class="label">Phone Number</p>
    </div>
    <div class="cont1">
      <h6>{newDatas.personalInformation.phoneNumber}</h6>
    </div>
  </div>

  <div class="container-main3">
    <div class="cont">
    <p class="label">Lead Source</p>
    </div>
    <div class="cont1">
      <h6>{newDatas. generalInformation.source}</h6>
    </div>
    
  </div>

  <div class="container-main4">
    <div class="cont">
    <p class="label"> Lead Status</p>
    </div>
    <div class="">
    <span className="badge" style={{
  height: "80%",
  width: "100%",
  color: ['Intrested', 'joined', 'joined-payment completed'].includes(newDatas.personalInformation.leadStatus) ? 'green' : 'red',
  border: `1px solid ${['Intrested', 'joined', 'joined-payment completed'].includes(newDatas.personalInformation.leadStatus) ? 'green' : 'red'}`,
  padding: "0.6rem",
  textAlign: 'center',
}}>
        {newDatas.personalInformation.leadStatus}</span>                                        

                </div>

    
  </div>
</div>
</div>

            </div>
            <div className='d-flex justify-content-end'>
            <button style={{width:'4rem',height:'1rem'}} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            </button>
            </div>
        </div>

            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div style={{color:'white' }}></div>

                <div class="container6">
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="info-boxa">
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Age</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.personalInformation.age}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">DOB</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.personalInformation.dateOfBirth}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Father's Name</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.personalInformation.fatherName}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Mother's Name</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.personalInformation.motherName}</p>
          </div>
        </div>
      </div>

      <div class="info-boxa">
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Blood Group</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.personalInformation.bloodGroup}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Street No</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.address.streetNo}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Street</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.address.streetName}</p>
          </div>
        </div>
      </div>

      <div class="info-boxa">
        <div class="row">
          <div class="col-sm-6">
            <p class="label">District</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.address.district}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">State</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.address.state}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Pincode</p>
          </div>
          <div class="col-sm-6">
            <p class="value">{newDatas.address.pincode}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="label">Country</p>
          </div>
          <div class="col-sm-6">
            <p class="value">India</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  </div>
</div>

   </div>     
       
  

        <div class="accordion-item mt-5">
        <div  style={{height: 'auto',overflowY:'scroll',paddingTop:'1rem'}} className="scroll accordion accordion-flush" id="accordionFlushExample">
          
        <div class="accordion-header"style={{overflow:"hidden"}}>
            <div className="container pt-2 d-flex">
            <div className="col-2">
            </div>
              <div className="col-md-12 col-lg-12">
              <div class="container-mainname">
  <div class="container-mainname1">
    <div class="contname">
      <h3 style={{color:"white",marginLeft:"0px"}}>CONVERSATION</h3>
    </div>
  </div> 
</div>
</div>
</div>
            <div className='d-flex justify-content-end'>
            <button style={{width:'4rem',height:'1rem'}} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
            </button>
            </div>
           
        </div>
            <div id="flush-collapseOne2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                <div style={{color:'white',padding:'1rem'}}></div>
                <div className="btn-group" role="group" aria-label="Second group" >
                   </div>

                     
                   <div className="container7" style={{overflowY:"scroll",height:"60vh"}}>
  <div className="row" style={{ padding: "1.5rem" }}>
    <div className="col-md-12 col-lg-12 position-relative">
      <div>
        {massage.length > 0 ? (
          massage.map((mess, index) => {
            const [message, date] = mess.split(".");
            return (
              <div key={index} className="message-bubble-container">
                <div className="dbox-container">
                    {date && (
                      <div className="date-box">
                        <span>{formatDate(date)}</span>
                      </div>
                    )}
                  </div>
                <div className="message-bubble">
            <h5>{message}</h5>
            
          </div>
              </div>
            );
          })
        ) : (
          <h1>No messages</h1>
        )}
      </div>

      <hr className="divider1" />

      <input
        type="text"
        className="form-control input-field"
        id="exampleFormControlInput1"
        placeholder="Enter your text here"
        value={convo}
        onChange={(e) => setConvo(e.target.value)}
      />

      <div className="position-absolute top-0 end-0"></div>

      <div className="input-container">
        <div className="top-inputs"  style={{marginBottom:'1rem'}}>
          <select
            className="p-3 bg-black rounded-3"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" disabled selected>
              Lead Status
            </option>
            <option value="Notintrested">Not interested</option>
            <option value="Intrested">Interested</option>
            <option value="wrongnumber">Wrong Number</option>
            <option value="wronglead">Wrong Lead</option>
            <option value="joined">Joined</option>
            <option value="joined-payment completed">Joined-payment completed</option>
          </select>
        </div>
      </div>
     <Button
  variant="contained"
  onClick={handleClick}
  sx={{
    
    backgroundColor: '#f00d88', // Custom background color
    color: '#fff', // Text color
    padding: '8px 16px', // Padding
    fontSize: '10px', // Font size
    borderRadius: '8px', // Border radius
    width: '150px', // Small width
    '&:hover': {
      backgroundColor: '#f00d88', // Hover effect
    },
    '@media (max-width: 600px)': {
      width: '120px', // Smaller width for small screens
      padding: '6px 12px', // Adjust padding for small screens
    },
    '@media (max-width: 400px)': {
      width: '100px', // Even smaller width for very small screens
      padding: '4px 8px', // Further adjustment to padding
    },
  }}
>
  Update Conversation
</Button>
    </div>
  </div>
</div>

    </div>
    </div>
   </div>
  
        <div class="accordion-item mt-5">
        <div  style={{height: 'auto',overflowY:'scroll',paddingTop:'1rem'}} className="scroll accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-header" style={{overflow:"hidden"}}>
            <div className="container pt-2 d-flex">
            <div className="col-2">
            </div>

            <div class="container-mainname">
  <div class="container-mainname1">
    <div class="contname">
    <h3 style={{color:"white",marginLeft:"0px"}}>SCHEDULES</h3>
    <div className='d-flex align-items-center'>
            <button className="add-call-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Schedule Call</button>
            </div>
    </div>
    
  </div> 
  
</div>
    
            
            </div>



            <div className='d-flex justify-content-end'>
            <button style={{width:'4rem',height:'1rem'}} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
            </button>
            </div>
        </div>
            <div id="flush-collapseOne3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                <div style={{color:'white',padding:'2rem'}}></div>
                <div className="btn-group" role="group" aria-label="Second group" >                
                     </div>

                    
                     <div className="container">
        <div className="row">
        <div id="viewvip-table-container">
      <div id="table-container">
      <div class="contnames">
      <h5 style={{color:"white",padding:"10px"}}>Schedule Meeting</h5>
    </div>
                        <div className="scroll">
                            <table className="table  table-hover">
                                <thead>
                                <tr>
                                        <th className="t-head rounded-start-3" scope="col">S.no</th>
                                        <th className="t-head" scope="col">Scheduler Type</th>
                                        <th className="t-head" scope="col">Scheduler Name</th>
                                        <th className="t-head " scope="col">Scheduled Date</th>
                                        <th className="t-head " scope="col">Scheduled Time</th>
                                        <th className="t-head " scope="col">Completion</th>
                                        <th className="t-head rounded-end-3" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="table-dark">
                                {Array.isArray(meetings) && meetings.length > 0 ? (
    meetings.map((mess, index) => (
        <tr key={index}>
            <td>{index+1}</td>
            <td>{mess.schedulerType}</td>
            <td>{mess.schedulerName}</td>
            <td>
                <span
                    className="badge"
                    style={{
                        height: "80%",
                        width: "100%",
                        color: '#3ac4d6',
                        border: '1px solid #3ac4d6',
                        padding: "0.6rem",
                        textAlign: 'center'
                    }}
                >
                    {mess.scheduledDate}
                </span>
            </td>
            <td>
                <span
                    className="badge"
                    style={{
                        height: "80%",
                        width: "100%",
                        color: '#d6eb3a',
                        border: '1px solid #d6eb3a',
                        padding: "0.6rem",
                        textAlign: 'center'
                    }}
                >
                    {mess.scheduledTime}
                </span> 
            </td>
            <td>
                <div id="check-box">
                    <div className="checkbox-bx">
                        <label>
                            <input type="checkbox"
                             checked={mess.meetingStatus !== "inactive"}
                            name="" id=""
                            onClick={()=>{handlemeetingactived(mess._id,mess.meetingStatus == "inactive"?{
                              schedulerType:mess.schedulerType,
                              schedulerName:mess.schedulerName ,
                                scheduledDate:mess.scheduledDate,
                               scheduledTime:mess.scheduledTime,
                              meetingStatus:"active",

                            }:{
                              schedulerType:mess.schedulerType,
                              schedulerName:mess.schedulerName ,
                                scheduledDate:mess.scheduledDate,
                               scheduledTime:mess.scheduledTime,
                              meetingStatus:"inactive",

                            })}} />
                            <span></span>
                        </label>
                    </div>
                </div>
            </td>
            <td>
                <span
                    className="badge"
                    style={{
                        height: "80%",
                        width: "100%",
                        color: 'Red',
                        border: '1px solid Red',
                        padding: "0.6rem",
                        textAlign: 'center'
                    }}
                    onClick={()=>{handleMeetingDelete( mess._id)}}
                >
                    Delete
                </span>
            </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="7">No meetings available</td>
    </tr>
)}
                                
                                </tbody>
                            </table>
              </div>
            </div>
          </div>
               </div>
            
        </div>
                </div>
</div>
            </div>

  

  
       
       
        

        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Schedule Call</h5>
              </div>
              <div className="modal-body">
              <form onSubmit={handleSubmit}>
      <div className="form-group row">
        <label htmlFor="schedulerType" className="col-sm-2 col-form-label">Scheduler Type</label>
        <div className="col-sm-10">
          <div className="button-like-input">
            <input 
              type="text" 
              readOnly 
              className="form-control-plaintext" 
              id="schedulerType" 
              value={formData.schedulerType} 
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="schedulerName" className="col-sm-2 col-form-label">Scheduler Name</label>
        <div className="col-sm-10">
          <div className="button-like-input">
            <input 
              type="text" 
              readOnly 
              className="form-control-plaintext" 
              id="schedulerName" 
              value={formData.schedulerName} 
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="scheduledDate" className="col-sm-2 col-form-label">Scheduled Date</label>
        <div className="col-sm-10">
          <input 
            type="date" 
            className="form-control" 
            id="scheduledDate" 
            value={formData.scheduledDate} 
            onChange={handleChange} 
          />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="scheduledTime" className="col-sm-2 col-form-label">Scheduled Time</label>
        <div className="col-sm-10">
          <input 
            type="time" 
            className="form-control" 
            id="scheduledTime" 
            value={formData.scheduledTime} 
            onChange={handleChange} 
          />
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary" onClick={handlemeeting}>Schedule Call</button>
      </div>
    </form>
              </div>
             
            </div>
          
     </div>
     </div>
        
     </div>
     </div>

    </Crmleadprofilecontainer>
 
  )
}




const Crmleadprofilecontainer=styled.div`
.meta-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
    font-size: 12px;
  }
    color: white;
    height: auto;
    width:100%;
    padding:2rem;
    margin-top: 2rem;
    margin-bottom:6rem;
    .scroll{
    overflow-y: scroll;
    height: 100%;
}
.scroll::-webkit-scrollbar {
    width: 0px;
  height: 0px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: transparent;
}
.scroll::-webkit-scrollbar-button {
  height: 16px;
}
    .accordion-item{
        background-color: var( --navbar-dark-primary);
        border-radius:10px;
    }
    .accordion-button{
        color: var(--icon-color) !important;
    }
    h6{
        color: white;
        text-align: center;
        font-weight: 900;
    }
    
    .fa-solid{
        font-size: 1rem;
        color:var(--icon-color)
    }
    .text{
        margin-left: 0.6rem;
        font-weight: 600;
        font-size: 0.9rem;
        color: white;
    }

@media (min-width: 800px) and (max-width: 1180px) {
    .accordion-item {
        width: 70vw;
    }
}



.container-box1 {
  border: 1px solid red; /* Change color as needed */
  padding: 5px 25px 1px 20px; /* Adjust padding as needed */
  display: inline-block; /* Ensure the box takes only the required width */
  border-radius: 5px; /* Add some rounded corners */
  background-Color: red;
}


.input-field {
    width: 90%;
    height: 5rem; /* Adjust height as needed */
    padding-right: 40px; /* Ensure space for icons */
    position: relative;
}

.input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1rem;
  }

  .input-container .top-inputs {
    display: flex;
    width: 40%;
    gap: 10px;
  }

  .input-container input,
  .input-container select {
    flex: 1;
    border: 1px solid black;
    padding: 10px;
    color: white;
    background-color: black;
  }

  .input-container .update-conversation {
    width: 100%;
    margin-top: 10px;
  }

  .update-button {
    width: 20%;
    margin-top: 10px;
    padding: 10px;
    background-color: #ec008b;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }


  .container-main {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

  }
  .container-main > div {
    margin-right: 50px;
    margin-bottom:10px
    margin-left: 20px;
    margin-top: -20px;

  }
  .cont {
    flex: 0 0 100%;
    margin-bottom: 20px;
     color:white;
    font-size:18px;
    margin-right:0px;
    margin-bottom:0px;
  }
  .cont1{
    flex: 0 0 100%;
    margin-bottom: 35px;
    font-size:20px;
    color:white;
    margin-top:17px;
     
  }


  .container-mainname > div {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .container-mainname {
    margin-top: 40px;
  }
.contname {
    margin-bottom: 0px;
    color: #ec008bde;
    font-size:24px;
    
  }
.contnames{
color: #ec008bde;
    font-size:24px;
    }


  .container6 {
    position: relative; /* Set position to relative for absolute positioning of the icon */
    color: white;
    border-radius: 10px;
    background-color: #1d1e22;
    height: auto; /* Reduce the height */
    top: 0; /* Set top to 0 */
    left: 10px;
    max-width:800px;
    justify-content:center;
    width:auto;
       margin: 20px auto;
    padding: 3rem; /* Add padding for better spacing */
  }
  
  .container6 .row {
    display: flex;
    flex-wrap: wrap;
  }
  .container6 .col-md-6,
  .container6 .col-lg-4 {
    padding: 10px;
    box-sizing: border-box;
  }
  .info-boxa {
    position: relative;
    white-space: nowrap;
    margin-left: 3  0px;
  }
  .info-itema {
    display: inline-block; /* Display as inline-block elements */
    vertical-align: top; /* Align items to the top */
    margin-right: 130px; /* Add some space between each item */
    margin-bottom: 5px; /* Reduce the bottom margin */
    margin-left: 8px; 
  }
  .label {
    color: #ec008bde;
    font-size: 18px;
  }
    .value {
    font-size: 18px;
  }
 
  
.container7{
  position:relative; /* Set position to relative for absolute positioning of the icon */
  color:white;
  border-radius:10px;   
 background-Color: #1d1e22;
  height: auto;
  bottom:30px; 
  
    margin: 20px auto;
  max-width:900px;
  left:40px; 
  padding: 20px;
}
      
    .container1 {
        border-radius: 0.5rem;
        position: relative;
        transform: translateX(-50%);
        padding: 2rem;
        width: 50%;
        height: auto;
        left: 610px;
        background-color: var(--navbar-dark-primary);
    }

    .head-container{
      display: flex;
    }

    .divider {
        border-top: 3px solid rgb(0, 0, 0); /* Increase thickness */
        width: 100%;
        top: 10px;
        position: relative;
    }
    .header {
        text-align: center;
        margin-bottom: 20px;
    }

  .dbox-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child elements horizontally */
}

.date-box {
  background-color: #f0f0f0; /* Light gray background */
  padding: 0.5rem; /* Padding inside the box */
  margin-bottom: 0.5rem; /* Space below the date box */
  border-radius: 8px; /* Rounded corners */
  font-size: 0.6rem; /* Slightly smaller text */
  color: #333; /* Dark gray text color */
  text-align: center; /* Center the text within the date box */
  display: inline-block; /* Adjust the width based on content */
  border: 1px solid #ddd; /* Light border*/
   /* Ensure auto width for the content */
}

  .date {
    margin: 0;
    color: #333;
    font-size: 14px;
  }
  .receiver-box {
    background-color: #b24185c2;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    color: white;
  }

  .message-bubble {
  background-color: #b24185c2; /* light green */
  border-radius: 20px;
  padding: 10px 15px;
  margin: 10px 0;
  width: auto;
  position: relative;
  clear: both;
  color: white;

  /* Ensure text wraps within the bubble */
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%; /* Prevent the bubble from exceeding the container width */
  box-sizing: border-box; /* Include padding and border in width calculation */
}
    .message-bubble::before {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }
  
    .message-text {
        margin: 5px;
        position: relative;
    }
  
    .timestamp {
        margin-top: 110px;
        font-size: 0.8em; /* Smaller font size */
        color: #333; /* Gray color */
        position: absolute;
        bottom: -10px; /* Position it below the message */
        right: 10px; /* Position it at the end of the message */
    }


.icon-container {
  position: absolute;
  top: 50%;
  right: -70px; /* Adjust as needed */
  transform: translateY(-50%);
}

.icon-container i {
  cursor: pointer;
  color: #666;
}





.divider1 {
  border: 1px solid #ddd;
  margin: 20px 0;
}
    
    .mt-3 {
    margin-top: 1rem !important;
  }

  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    
    padding: 1rem;
    border-radius: 10px;
    height: auto;
    {/* width:130vh; */}
    overflow-y: scroll;
    margin-top:-40px;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  #check-box .checkbox-bx label{
    position: relative;
    height: 24px;
    width: 24px;
}
#check-box .checkbox-bx input{
    width: 100%;
    height: 100%;
    position:absolute;
    opacity: 0;   
}

#check-box  .checkbox-bx label span{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: black;
}

#check-box  .checkbox-bx label span::after{
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s ,height 0.5sec,opacity 0.5sec;
}

#check-box  .checkbox-bx input:checked ~ span::after{
    width: 10px;
    height: 20px;
    opacity: 1;
}
/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .scroll {
    max-height: 87vh;
  }
 
}
@media (max-width: 576px) {
  .scroll {
    max-height:50vh;
  }
}

.add-call-btn {
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-right: 1rem;
    position: relative;
    top: 50%;

}

.modal-content {
    background-color: #25272d;
    color: white;
}

.modal-header {
    border-bottom: 1px solid #444;
}

.modal-footer {
    border-top: 1px solid #444;
}

.modal-body input.{
    color: white;
}

.modal-body label {
    color: white;
}
.modal-body input::placeholder {
    color: white;
}
    .button-like-input {
  background-color:white; /* Bootstrap primary button color */
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block; /* Ensure it wraps around content */
}

.button-like-input input {
  color: black;
  background-color: transparent; /* Make input background transparent */
  border: none;
  width: 100%; /* Ensure input takes full width of parent */
}


.btn-primary {
    background-color: green;
    border: none;
}








/* Media query for larger screens */
@media (min-width: 769px) {
  .checkbox-container .btn {
    flex: 0 0 calc(33.53% - 50px); /* Adjust button width for larger screens */
  }
  .password-field {
    width: calc(25% - 20px); /* Adjust width of password field for larger screens */
  }
  .toggle-button-wrapper {
    left: calc(30% + -10px); /* Adjust position of toggle button for larger screens */
    top: 90%; /* Align toggle button vertically */
    transform: translateY(-50%); /* Center toggle button vertically */
  }
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .container9 {
    padding: 10px;
    margin-right: 20px;

  }

  .checkbox-container .btn {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .password-box {
    margin-top: 20px;
  }

  .toggle-button-wrapper {
    left: calc(100% - 30px);
  }
  .password-field {
    width: calc(60% - 20px); /* Adjust width of password field for smaller screens */
  }

  .toggle-button-wrapper {
    left: calc(55% + 15px); /* Adjust horizontal position of toggle button for smaller screens */
    top: 95%; /* Align toggle button vertically */
    transform: translateY(-50%); /* Center toggle button vertically */
  }
}




/* Media queries for responsiveness */
@media (max-width: 1430px) {
  /* Adjustments for smaller devices */

  #img-sec{
    height: 40%;
    width: 5rem;
    overflow: hidden;
    border-radius: 100%;
    cursor: pointer;
  }
 
  .container-box3{
    height:10px;
    width:50px;
    border: 1px solid #ffffff;; /* Border style */
    border-radius:0px; /* Border radius */
    margin: 0px; /* Add margin as needed */
    top:-60px;
    right:0px;
    padding:0px; 
    
    color:white;
    padding: 5px 5px 15px 10px; /* Adjust padding to move text within button */
}
.head{
  font-size:6px;
}

.container-main1,
.container-main2,
.container-main3,
.container-main4 {
  left: 0;
}

.cont,
.cont1 {
  flex: 0 0 calc(50% - 20px);
}

  .container6 {
    width: 90%;
    height: auto;
    max-width: none;
    margin: 20px auto;
    left: 0;
  }

  .container7,
  .container8 {
    width: 90%;
    height: auto;
    max-width: none;
    margin: 20px auto;
    left: 0;
  }

  .info-box,
  .info-boxa,
  .info-boxy {
    margin-left: 0;
  }

  .info-item,
  .info-itema,
  .info-itemy, {
    display: block;
    margin-right: 0;
    
  }

  .info-item:last-child {
    margin-bottom: 0;
  }
}
 

  @media (max-width: 768px) {
    .cont,
    .cont1,
    .contname,
    .contnames {
        font-size: 1.2rem;
    }

    .header {
        font-size: 1.5rem;
    }

    .date-box,
    .message-bubble,
    .timestamp,
    .icon-container {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .cont,
    .cont1,
    .contname,
    .contnames {
        font-size: 1rem;
    }

    .header {
        font-size: 1.2rem;
    }

    .input-container input,
    .input-container select {
        font-size: 0.8rem;
    }

    .date-box,
    .message-bubble,
    .timestamp,
    .icon-container {
        font-size: 0.7rem;
    }

    .update-button {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .cont,
    .cont1,
    .contname,
    .contnames {
        font-size: 0.9rem;
    }

    .header {
        font-size: 1rem;
    }

    .input-container input,
    .input-container select {
        font-size: 0.7rem;
    }

    .date-box,
    .message-bubble,
    .timestamp,
    .icon-container {
        font-size: 0.6rem;
    }

    .update-button {
        font-size: 0.7rem;
        width:auto;
    }
}

@media (max-width: 360px) {

    .cont,
    .cont1,
    .contname,
    .contnames {
        font-size: 0.8rem;
    }

    .header {
        font-size: 0.9rem;
    }

    .input-container input,
    .input-container select {
        font-size: 0.6rem;
    }

    .date-box,
    .message-bubble,
    .timestamp,
    .icon-container {
        font-size: 0.5rem;
    }

    .update-button {
        font-size: 0.6rem;
    }
}
}

`
export default Crmleadprofile