import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import { Box, Tabs, Tab, CircularProgress } from "@mui/material";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import { URL as url } from "../../assets/mocData/config";
import { fetchData } from "../../Api";
import { Pagination, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
function CompanyBusinessContainer() {
  const [selectedView, setSelectedView] = useState("CompanyRevenue");
  const [filterData, setFilterData] = useState(""); // This holds the search input value
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const FilterOpen = () => {
    setFilter(!filter);
  };

  const handleChange = (event, newValue) => {
    setSelectedView(newValue);
  };

  return (
    <ConceptTableContainer>
      <div id="course-list" style={{ height: "100vh", overflowY: "scroll", position: "relative" }}>
        {loading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: "#f00d88" }} />
          </LoadingOverlay>
        )}
        <TopContainer
          heading="BUSINESS DETAILS"
          options={["Active", "InActive"]}
          filter={filter}
          FilterOpen={FilterOpen}
          setFilterData={setFilterData} // Passing setFilterData to handle search input
          showFields={{ search: true, filter: true }} // Enable search and filter
        />

        <Box
          sx={{
            marginTop: "1rem",
            width: "100%",
            backgroundColor: "var(--navbar-dark-primary)",
            borderRadius: "8px",
            boxShadow: 3,
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedView}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Concept Navigation Tabs"
            textColor="inherit"
            indicatorColor="secondary"
            sx={{
              "& .MuiTab-root": {
                minWidth: "150px",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "white",
                "&.Mui-selected": {
                  color: "white",
                },
                margin: "0 0.5rem",
              },
              "& .MuiTabs-indicator": {
                height: "4px",
                backgroundColor: "#f00d88",
              },
            }}
          >
            <Tab label="Company Revenue" value="CompanyRevenue" />
            <Tab label="Vip Status" value="VipStatus" />
          </Tabs>
        </Box>

        {/* Pass filterData to the respective sections */}
        {selectedView === "CompanyRevenue" && (
          <CompanyRevenue filterData={filterData} /> // Pass filterData here
        )}
        {selectedView === "VipStatus" && <VipStatus filterData={filterData} />} // Pass filterData here
      </div>
    </ConceptTableContainer>
  );
}


function CompanyRevenue({ filterData }) {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [StudentReferrals, setStudentReferrals] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 50; // Number of items per page

  const FilterOpen = () => {
    setFilter((prev) => !prev);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchStudentReferrals = async () => {
      const header = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        const studentReferrals = await fetchData(
          `${url}/students/admin/pending`,
          header
        );
        setStudentReferrals(studentReferrals);
      } catch (error) {
        console.error("Error fetching student referral table:", error);
      }
    };

    fetchStudentReferrals();
  }, [userRole, userToken]);

  const filteredData = StudentReferrals.filter((user) => {
    const studentName = user.personalInfo.studentName
      ?.toLowerCase()
      .includes(filterData.toLowerCase());
    const branchReferred = user.courseDetail.branchName.branchName
      ?.toLowerCase()
      .includes(filterData.toLowerCase());
    const courseName = user.courseDetail.courseName.courseName
      ?.toLowerCase()
      .includes(filterData.toLowerCase());
    const referredVip = user.referenceName
      ?.toLowerCase()
      .includes(filterData.toLowerCase());
    return studentName || branchReferred || courseName || referredVip;
  });

  // Calculate the data to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ReferedVipStyled>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.No
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>
                <th className="t-head" scope="col">
                  Branch Referred
                </th>
                <th className="t-head" scope="col">
                  Course Name
                </th>
                <th className="t-head" scope="col">
                  Referred Vip
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
            {currentData.length > 0 ? (
  currentData.map((user, i) => (
    <tr key={i}>
      <td>{i + 1 + indexOfFirstItem}</td> {/* Maintain serial number */}
      <td>{user.personalInfo.studentName}</td>
      <td>{user.courseDetail.branchName.branchName}</td>
      <td>{user.courseDetail.courseName.courseName}</td>
      <td>{user.referenceName}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="5">No data available.</td>
  </tr>
)}

            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="d-flex justify-content-center mt-3">
        <Stack
          alignItems={"center"}
          marginTop={"0.5rem"}
          marginBottom={"1rem"}
        >
          <Paginations
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}


function VipStatus({ filterData }) {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userToken, userRole, profileData, setProfileViewData } = useGlobalContext();
  const [UserData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 50; // Number of items per page

  const FilterOpen = () => {
    setFilter((prev) => !prev);
  };

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchVIP = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };

    try {
      const response = await fetchData(`${url}/vip/`, header);
      setUserData(response.data); // Setting fetched data
      console.log(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  };

  useEffect(() => {
    fetchVIP();
  }, [userRole, userToken]);

  const filteredData = UserData.filter((user) => {
    const vipName = user.userName ? user.userName.toLowerCase().includes(filterData.toLowerCase()) : false;
    const vipStatus = user.vipStatus ? user.vipStatus.toLowerCase().includes(filterData.toLowerCase()) : false;
    return vipName || vipStatus;
  });
  

  // Calculate the data to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ReferedVipStyled>
      {/* VIP List Table */}
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.No</th>
                <th className="t-head" scope="col">VIP Name</th>
                {/* <th className="t-head" scope="col">Total Collection</th> */}
                <th className="t-head" scope="col">VIP Status</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {currentData.length > 0 ? (
                currentData.map((data, i) => (
                  <tr key={i}>
                    <td>{i + 1 + indexOfFirstItem}</td> {/* Maintain serial number */}
                    <td>{data.userName}</td>
                    {/* <td>{data.totalCollection}</td> Replace with actual collection if available */}
                    <td><span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: "white", // Text color matches the border color
      border: `1px solid white`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >{data.otherDetails.vip_status}</span></td> {/* Replace with actual status */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No VIP data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(UserData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}

  export default CompanyBusinessContainer;

const ConceptTableContainer = styled.div`
  width: 100%;
  #course-list {
    width: 100%;
    padding: 2rem;
    height: 100vh;
     position: relative;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }

  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;


const ReferedVipStyled = styled.div`
  width: 100%;
  padding: 2rem;
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }
  h2 {
    color: white;
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 80vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .btn-group label.btn {
    font-size: 0.7rem !important; /* Decrease font size inside buttons */
    padding: 0.5rem 0.5rem !important; /* Adjust button padding */
  }
  .btn-check:checked + .btn {
    background-color: var(--navbar-dark-primary);
    color: white;
    border-color: var(--navbar-dark-primary);
  }
  .btn-check:checked + .btn.red {
    background-color: red; /* Red color */
  }
  .btn-check:checked + .btn.warning {
    background-color: orange; /* Warning color (orange) */
  }
  .btn-check:checked + .btn.green {
    background-color: green; /* Green color */
  }
`;

const Paginations = styled(Pagination)`
color: white; // Set text color to white
& .MuiPaginationItem-root {
  color: white; // Ensure pagination items have white text
}
& .MuiPaginationItem-root.Mui-selected {
  background-color: #333; // Optionally, set background for selected item
  color: white; // Ensure selected pagination item has white text
}
`;
