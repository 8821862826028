import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { postData, fetchData } from '../../../Api';
import { URL as url } from '../../../assets/mocData/config';
import { useGlobalContext } from '../../../assets/contex/GlobalContext';
import axios from 'axios';
import { Pagination, Stack } from "@mui/material";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`

const CrmAdminFranchiseViewContainer = styled.div`
    color: white;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box; /* Ensure padding doesn't affect width */ 

    input {
        color: black;
    }

    .btn-verify {
        background-color: green;
        color: white;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        cursor: pointer;
        margin-left: 0.5rem;
    }

    #pay-btn {
        width: 100%; 
        max-width: 10rem; /* Maintain a max width for larger screens */
        background-color: #25272d;
        color: greenyellow;
        border-radius: 10px;
        padding: 0.5rem;
        border: 1px solid greenyellow;
    }

    .scroll {
        overflow-y: auto;
        max-height: 80vh;
    }

    .accordion-item {
        background-color: var(--navbar-dark-primary);
    }

    .accordion-button {
        color: var(--icon-color) !important;
    }

    h6, h4, h5 {
        color: white;
        padding: 1rem;
        font-weight: 900;
    }

    p {
        color: white;
    }

    .fa-solid {
        font-size: 1rem;
        color: var(--icon-color);
    }

    .text {
        margin-left: 0.6rem;
        font-weight: 600;
        font-size: 0.9rem;
        color: white;
    }

    .mt-3 {
        margin-top: 1rem !important;
    }

    .t-head, td {
        text-align: center;
        padding: 1rem; /* Adjust padding for better responsiveness */
    }

    #table-container {
        background-color: #25272d;
        margin-top: 1rem;
        padding: 1rem;
        border-radius: 10px;
        height: auto;
        overflow-y: auto;
        width: 100%;
    }

    .t-head {
        color: white !important;
        background-color: #18171b !important;
        text-align: center;
    }

    .rounded-corners {
        border-spacing: 0;
        border-collapse: separate;
        border-radius: 10px;
    }

    .rounded-corners th, .rounded-corners td {
        border: 1px solid black;
    }

    thead tr {
        border-bottom: 1rem solid #25272d;
    }

    tr {
        border-color: #25272d;
    }

    .table > :not(caption) > * > * {
        background-color: #25272d;
    }

    td {
        padding: 1rem !important; /* Adjusted padding for responsiveness */
    }

    .button-33 {
        background-color: #f00d88; 
        border-radius: 100px;
        box-shadow: rgba(240, 13, 136, 0.2) 0 -25px 18px -14px inset, rgba(240, 13, 136, 0.15) 0 1px 2px, rgba(240, 13, 136, 0.15) 0 2px 4px, rgba(240, 13, 136, 0.15) 0 4px 8px, rgba(240, 13, 136, 0.15) 0 8px 16px, rgba(240, 13, 136, 0.15) 0 16px 32px;
        color: #fff; 
        cursor: pointer;
        display: inline-block;
        font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
        padding: 7px 20px;
        text-align: center;
        text-decoration: none;
        transition: all 250ms;
        border: 0;
        font-size: 16px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-33:hover {
        box-shadow: rgba(240, 13, 136, 0.35) 0 -25px 18px -14px inset, rgba(240, 13, 136, 0.25) 0 1px 2px, rgba(240, 13, 136, 0.25) 0 2px 4px, rgba(240, 13, 136, 0.25) 0 4px 8px, rgba(240, 13, 136, 0.25) 0 8px 16px, rgba(240, 13, 136, 0.25) 0 16px 32px;
        transform: scale(1.05) rotate(-1deg);
    }

    /* Media queries for better responsiveness */

    @media (max-width: 1024px) {
        padding: 1.5rem; /* Adjust padding for smaller screens */
        td {
            padding: 1rem !important; /* Adjust padding */
        }
    }

    @media (max-width: 800px) {
        padding: 1rem; /* Further adjust padding for tablets */
        td {
            padding: 0.8rem !important; /* Adjust padding */
            font-size: 0.9rem; /* Adjust font size */
        }
        #pay-btn {
            width: 100%; /* Full width on smaller screens */
            max-width: 7rem; /* Adjust max width */
        }
        .t-head {
            font-size: 0.9rem; /* Smaller font size for smaller screens */
        }
    }

    @media (max-width: 576px) {
        padding: 0.5rem; /* Further adjust padding for mobile */
        #pay-btn {
            width: 100%; /* Full width on mobile */
        }
        .scroll {
            max-height: 40vh; /* Adjust max height for mobile screens */
        }
        td {
            padding: 0.6rem !important; /* Adjust padding */
            font-size: 0.8rem; /* Smaller font size for mobile */
        }
        .t-head {
            font-size: 0.8rem; /* Smaller font size for mobile */
        }
        .button-33 {
            font-size: 14px; /* Smaller button font size for mobile */
            padding: 5px 15px; /* Adjust padding */
        }
    }

    /* Additional media query for screens below 480px */
    @media (max-width: 480px) {
        .scroll {
            max-height: 35vh; /* Adjust max height for very small screens */
        }
        td {
            padding: 0.5rem !important; /* Adjust padding */
            font-size: 0.7rem; /* Further smaller font size */
        }
        .t-head {
            font-size: 0.7rem; /* Smaller font size for very small screens */
        }
        .button-33 {
            font-size: 12px; /* Smaller button font size for very small screens */
            padding: 4px 12px; /* Adjust padding */
        }
    }
`;



function CrmUserVipViewAddlead() {
    const { profileData } = useGlobalContext();
    const { userToken, userRole } = useGlobalContext();
    const [fId, setFId] = useState("");
    const [fname, setFname] = useState("");
    const [source, setSource] = useState("");
    const [mapDatas, setMapDatas] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const {enqueueSnackbar}=useSnackbar();
    const location = useLocation();
    const studentData = location.state?.studentData || {}; // Retrieving passed student data
    const navigate = useNavigate();

    let headers = {
      userRole: userRole,
      userToken: userToken,
    };
    const today = new Date().toISOString().split('T')[0];

    const [formData, setFormData] = useState({
      personalInformation: {
        studentName: "",
        phoneNumber: "",
        age: "",
        bloodGroup: "",
        dataOfBirth: "",
        gender: "",
        fatherName: "",
        motherName: "",
        degree: "",
      },
      address: {
        streetNo: "",
        streetName: "",
        landmark: "",
        district: "",
        state: "",
        pincode: "",
      },
    });
  
    const itemsPerPage = 10; 
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = mapDatas.slice(indexOfFirstItem, indexOfLastItem);
  
    const handlePageChange = (event, value) => {
      setCurrentPage(value);
    };
  
    const handleFile = (e) => {
      const { name, value } = e.target;
      const [section, field] = name.split('.');
    
      setFormData((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value,
        },
      }));
    };
  
    // Fetch user details
    const getDetails = async () => {
      try {
        const newData = { userId: fId };
        const data = await axios.post(`${url}/details`, newData, { headers });
        const datasss = data.data;
    
        if (datasss && datasss.length > 0) {
          setFname(datasss[0].userName);
        } else {
          console.error("No data found for the given user ID");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("Failed to fetch user details. Please try again.");
      }
    };
  
    // Fetch data for leads and user profile
    async function getDataApi() {
      try {
        const response = await fetchData(`${url}/leads`, headers);
        setMapDatas(response);
        setFname(profileData.profileData.userName);
        setFId(profileData.profileData.user_id);
        setSource(profileData.profileData.user_id);
        console.log(response);
      } catch (error) {
        console.error("Error fetching leads data:", error);
        alert("Failed to fetch leads data. Please try again later.");
      }
    }
  
    // Populate formData with student data when the component mounts
    useEffect(() => {
        if (studentData) {
            setFormData({
                personalInformation: {
                    studentName: studentData.personalInfo?.studentName || "",
                    phoneNumber: studentData.familyDetails?.fathersPhone || "",
                    age: studentData.personalInfo?.age || "",
                    bloodGroup: studentData.personalInfo?.bloodGroup || "",
                    dataOfBirth: studentData.personalInfo?.dataOfBirth || "",
                    gender: studentData.personalInfo?.gender || "",
                    fatherName: studentData.familyDetails?.fathersName || "",
                    motherName: studentData.familyDetails?.mothersName || "",
                    degree: studentData.educationInfo?.degree || "",
                },
                address: {
                    streetNo: studentData.address?.streetNo || "",
                    streetName: studentData.address?.streetName || "",
                    landmark: studentData.address?.landmark || "",
                    district: studentData.address?.district || "",
                    state: studentData.address?.state || "",
                    pincode: studentData.address?.pincode || "",
                },
            });
        }
    }, [studentData]);
    
  
    useEffect(() => {
      getDataApi();
    }, [fId]);
  
    useEffect(() => {
      if (fId) {
        getDetails();
      }
    }, [fId]);
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log("Submitting form...");
  
      const newFormData = {
        generalInformation: {
          followersId: fId,
          followersName: fname,
          source: source,
        },
        ...formData,
      };
  
      try {
        const data = await postData(`${url}/leads`, newFormData, headers);
        console.log("Form submitted successfully:", data);
  
        // Reset form fields after successful submission
        setFname("");
        setSource("");
        setFormData({
          personalInformation: {
            studentName: "",
            phoneNumber: "",
            age: "",
            bloodGroup: "",
            dataOfBirth: "",
            gender: "",
            fatherName: "",
            motherName: "",
            degree: "",
          },
          address: {
            streetNo: "",
            streetName: "",
            landmark: "",
            district: "",
            state: "",
            pincode: "",
          },
        });
        setFId("");
        navigate("/studentsdetails");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
    
    if(userRole=='franchise' || userRole=='branchstaff')
        {
            return (
                <CrmAdminFranchiseViewContainer>
        { userRole=='franchise'?(
                    <div id="table-container"style={{height:"auto"}}>
                        <h4>ADD LEAD</h4>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                                className="button-4 btn btn-secondary"
                                style={{
                                    backgroundColor: '#f00d88',
                                    borderColor: 'rgba(27, 31, 35, 0.15)',
                                    borderRadius: '6px',
                                    boxShadow: 'rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset',
                                    boxSizing: 'border-box',
                                    color: '#24292E',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    fontFamily: '-apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    listStyle: 'none',
                                    padding: '6px 16px',
                                    position: 'relative',
                                    transition: 'background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1)',
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    touchAction: 'manipulation',
                                    verticalAlign: 'middle',
                                    whiteSpace: 'nowrap',
                                    wordWrap: 'break-word',
                                    height: 'auto',
                                    borderRadius: '0.25rem'
                                  }}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#form-container"
                                aria-expanded="false"
                                aria-controls="form-container"
                            >
                                ADD
                            </button>
                        </div>
                        <form className="container" onSubmit={handleSubmit}>
                            <div id="form-container" className="collapse">
                                <div className="scroll">
                                    <div className="container">
                                        <div className="row mb-3">
                                            <span className="col-12 col-md-3">Personal Information</span>
                                            <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                            <input
    style={{ width: "100%" }}
    className="form-control mb-2 me-md-2 inputField"
    type="text"
    value={formData.personalInformation.studentName}
    name='personalInformation.studentName'
    placeholder="Student Name"
    onChange={handleFile}
/>

                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.phoneNumber"
                                                    placeholder="Phone Number"
                                                    value={formData.personalInformation.phoneNumber}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.age"
                                                    placeholder="Age"
                                                    value={formData.personalInformation.age}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name='personalInformation.bloodGroup'
                                                    placeholder="Blood Group"
                                                    value={formData.personalInformation.bloodGroup}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="date"
                                                    name='personalInformation.dataOfBirth'
                                                    placeholder="DOB"
                                                    value={formData.personalInformation.dataOfBirth}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.gender"
                                                    placeholder="Gender"
                                                    value={formData.personalInformation.gender}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.fatherName"
                                                    placeholder="Father's Name"
                                                    value={formData.personalInformation.fatherName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.motherName"
                                                    placeholder="Mother's Name"
                                                    value={formData.personalInformation.motherName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.degree"
                                                    placeholder="Class/School"
                                                    value={formData.personalInformation.degree}
                                                    onChange={handleFile}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row mb-3">
                                            <span className="col-12 col-md-3">Address</span>
                                            <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.streetNo"
                                                    placeholder="Street No/Door No"
                                                    value={formData.address.streetNo}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.streetName"
                                                    placeholder="Street Name"
                                                    value={formData.address.streetName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.landmark"
                                                    placeholder="Landmark"
                                                    value={formData.address.landmark}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.district'
                                                    value={formData.address.district}
                                                    onChange={handleFile}
                                                    type="text"
                                                    placeholder="District"
                                                />
                                                <input
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.state'
                                                    value={formData.address.state}
                                                    onChange={handleFile}
                                                    type="text"
                                                    placeholder="State"
                                                />
                                                <input
                                    style={{width:"100%"}}
                                        className="form-control mb-2 me-md-2"
                                        type="number"
                                        placeholder="Pincode"
                                        name="address.pincode"
                                        value={formData.address.pincode}
                                        onChange={handleFile}
                                    />
                                </div>
                                <div className="d-flex justify-content-center flex-wrap">
                                
                                <div class="vip ms-2 mb-3 p-2"><button id='pay-btn' type="button" class="btn btn-sm d-flex justify-content-center"  name="addvip">
                                  <div style={{color:'greenyellow',width:'100%'}}>CANCEL</div></button>
                                </div>
                                <div class="vip pl-4 mb-3 p-2">
                                    <button id='pay-btn' type="submit" class="btn btn-sm d-flex justify-content-center"  name="addvip"><div style={{color:'greenyellow',width:'100%'}} >ADD Lead</div></button>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>  
        ) : null }
        
        
        <div id="viewvip-table-container">
                        <div id="table-container"style={{height:"auto"}}>
                            <h5>FRANCHISE LEADS</h5>
                            <div className="scroll">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th className="t-head rounded-start-3" scope="col">S.No</th>
                                            <th className="t-head" scope="col">FRANCHISE ID</th>
                                            <th className="t-head" scope="col">FRANCHISE Name</th>
                                            <th className="t-head" scope="col">Lead Source</th>
                                          
                                            <th className="t-head" scope="col">Student Name</th>
                                            <th className="t-head" scope="col">Phone Number</th>
                                            <th className="t-head" scope="col">Lead Status</th>
                                            <th className="t-head" scope="col">Profile View</th>
                                            <th className="t-head" scope="col">Meeting Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-dark">

{mapDatas.reduce((rows, mapData, index) => {
    if (mapData.generalInformation && mapData.generalInformation.followersId.includes('franchise')) {
        // Determine the color based on lead status
        const statusColor = {
            'Notintrested': 'red',
            'joined':'green',
                                                'joined-payment completed':'green',
            'Intrested': 'green'
        }[mapData.personalInformation.leadStatus] || 'gray'; // Default to gray if status not found

        // Filter meetings that are scheduled for today
        const todayMeetings = mapData.meeting.filter(meeting => meeting.scheduledDate === today);

        // If there are no meetings today, prepare the message
        let scheduleMessage = todayMeetings.length > 0
        ? todayMeetings.filter(meeting => meeting.meetingStatus == 'inactive')
                      .map(meeting => `Meeting at ${meeting.scheduledTime}`)
                      .join(', ') || 'No active meetings today'
        : 'No meetings today';
        rows.push(
            <tr key={index}>
                <td>{rows.length + 1}</td> {/* Display serial number */}
                <td>{mapData.generalInformation.followersId}</td>  {/* Display Franchise ID */}
                <td>{mapData.generalInformation.followersName}</td>
                <td>{mapData.generalInformation.source}</td>
                <td>{mapData.personalInformation.studentName}</td>
                <td>{mapData.personalInformation.phoneNumber}</td>
                <td>
                    <span className="badge" style={{
                        height: "80%", width: "100%", color: 'white',
                        backgroundColor: statusColor, // Apply color based on status
                        border: `1px solid ${statusColor}`, // Border color matches the background
                        padding: "0.6rem", textAlign: 'center'
                    }}>
                        {mapData.personalInformation.leadStatus}
                    </span>
                </td>
                <td>
                    <a href={`/crmleadprofile/${mapData._id}`}>
                        <button className="badge" style={{
                            height: "80%", width: "100%", color: 'white',
                            border: '1px solid white', padding: "0.6rem", textAlign: 'center',
                            background: 'transparent', cursor: 'pointer'
                        }}>View Profile</button>
                    </a>
                </td>
                <td>{scheduleMessage}</td> {/* Display today's meeting schedule or no meetings */}
                <td>
                    <i className="fa-solid fa-trash"></i>
                    <i className="fa-solid fa-pen-to-square"></i>
                </td>
            </tr>
        );
    }
    return rows;
}, [])}

        </tbody>
        
                                </table>
                            </div>
                        </div>
                    </div>
        
                      
                </CrmAdminFranchiseViewContainer>
            );
        }
        else if (userRole=='vip')
        {
            return (
                <CrmAdminFranchiseViewContainer>
                    
                    <div id="table-container"style={{height:"auto"}}>
                        <h4>ADD LEAD</h4>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                                className="button-4 btn btn-secondary"
                                style={{
                                    backgroundColor: '#f00d88',
                                    borderColor: 'rgba(27, 31, 35, 0.15)',
                                    borderRadius: '6px',
                                    boxShadow: 'rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset',
                                    boxSizing: 'border-box',
                                    color: '#24292E',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    fontFamily: '-apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    listStyle: 'none',
                                    padding: '6px 16px',
                                    position: 'relative',
                                    transition: 'background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1)',
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    touchAction: 'manipulation',
                                    verticalAlign: 'middle',
                                    whiteSpace: 'nowrap',
                                    wordWrap: 'break-word',
                                    height: 'auto',
                                    borderRadius: '0.25rem'
                                  }}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#form-container"
                                aria-expanded="false"
                                aria-controls="form-container"
                            >
                                ADD
                            </button>
                        </div>
                        <form className="container" onSubmit={handleSubmit}>
                            <div id="form-container" className="collapse">
                                <div className="scroll">
                                    <div className="container">
                                        <hr />
                                        <div className="row mb-3">
                                            <span className="col-12 col-md-3">Personal Information</span>
                                            <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2 inputField"
                                                    type="text"
                                                    value={formData.personalInformation.studentName}
                                                    name='personalInformation.studentName'
                                                    placeholder="Student Name"
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.phoneNumber"
                                                    placeholder="Phone Number"
                                                    value={formData.personalInformation.phoneNumber}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.age"
                                                    placeholder="Age"
                                                    value={formData.personalInformation.age}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name='personalInformation.bloodGroup'
                                                    placeholder="Blood Group"
                                                    value={formData.personalInformation.bloodGroup}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="date"
                                                    name='personalInformation.dataOfBirth'
                                                    placeholder="DOB"
                                                    value={formData.personalInformation.dataOfBirth}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.gender"
                                                    placeholder="Gender"
                                                    value={formData.personalInformation.gender}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.fatherName"
                                                    placeholder="Father's Name"
                                                    value={formData.personalInformation.fatherName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.motherName"
                                                    placeholder="Mother's Name"
                                                    value={formData.personalInformation.motherName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="personalInformation.degree"
                                                    placeholder="Class/School"
                                                    value={formData.personalInformation.degree}
                                                    onChange={handleFile}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row mb-3">
                                            <span className="col-12 col-md-3">Address</span>
                                            <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.streetNo"
                                                    placeholder="Street No/Door No"
                                                    value={formData.address.streetNo}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.streetName"
                                                    placeholder="Street Name"
                                                    value={formData.address.streetName}
                                                    onChange={handleFile}
                                                />
                                                <input
                                                    style={{ width: "100%" }}
                                                    className="form-control mb-2 me-md-2"
                                                    type="text"
                                                    name="address.landmark"
                                                    placeholder="Landmark"
                                                    value={formData.address.landmark}
                                                    onChange={handleFile}
                                                />
                                                <select
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.district'
                                                    value={formData.address.district}
                                                    onChange={handleFile}
                                                >
                                                    <option value="" disabled>Select District</option>
                                                    <option value="district1">District 1</option>
                                                    <option value="district2">District 2</option>
                                                </select>
                                                <select
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.state'
                                                    value={formData.address.state}
                                                    onChange={handleFile}
                                                >
                                                    <option value="" disabled>Select State</option>
                                                    <option value="state1">State 1</option>
                                                    <option value="state2">State 2</option>
                                                </select>
                                                <input
                                    style={{width:"100%"}}
                                        className="form-control mb-2 me-md-2"
                                        type="number"
                                        placeholder="Pincode"
                                        name="address.pincode"
                                      
                                        onChange={handleFile}
                                    />
                                </div>
                                <div className="d-flex justify-content-center flex-wrap">
                                
                                <div class="vip ms-2 mb-3 p-2"><button id='pay-btn' type="button" class="btn btn-sm d-flex justify-content-center"  name="addvip">
                                  <div style={{color:'greenyellow',width:'100%'}}>CANCEL</div></button>
                                </div>
                                <div class="vip pl-4 mb-3 p-2">
                                    <button id='pay-btn' type="submit" class="btn btn-sm d-flex justify-content-center"  name="addvip"><div style={{color:'greenyellow',width:'100%'}} >ADD Lead</div></button>
                                </div>
        
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>  <div id="viewvip-table-container">
                        <div id="table-container"style={{height:"auto"}}>
                            <h5>VIP LEADS</h5>
                            <div className="scroll">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th className="t-head rounded-start-3" scope="col">S.no</th>
                                            <th className="t-head" scope="col">VIP ID</th>
                                            <th className="t-head" scope="col">VIP Name</th>
                                            <th className="t-head" scope="col">Lead Source</th>
                                            <th className="t-head" scope="col">Student Name</th>
                                            <th className="t-head" scope="col">Phone Number</th>
                                            <th className="t-head" scope="col">Lead Status</th>
                                            <th className="t-head" scope="col">Profile View</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-dark">
            {mapDatas.reduce((rows, mapData, index) => {
                if (mapData.generalInformation && mapData.generalInformation.followersId.includes('vip')) {
                    // Determine the color based on lead status
                    const statusColor = {
                        'Notintrested': 'red',
                        
                        'Intrested': 'green'
                    }[mapData.personalInformation.leadStatus] || '#F6C324'; // Default to gray if status not found
        
                    rows.push(
                        <tr key={index}>
                            <td>{rows.length + 1}</td> {/* Display serial number */}
                            <td>{mapData.generalInformation.followersId}</td>  {/* Display Franchise ID */}
                            <td>{mapData.generalInformation.followersName}</td>
                            <td>{mapData.generalInformation.source}</td>
                            <td>{mapData.personalInformation.studentName}</td>
                            <td>{mapData.personalInformation.phoneNumber}</td>
                            <td>
                                <span className="badge" style={{
                                    height: "80%", width: "100%", color: 'white',
                                    backgroundColor: statusColor, // Apply color based on status
                                    border: `1px solid ${statusColor}`, // Border color matches the background
                                    padding: "0.6rem", textAlign: 'center'
                                }}>
                                    {mapData.personalInformation.leadStatus}
                                </span>
                            </td>
                            <td>
                                <a href={`/crmleadprofile/${mapData._id}`}>
                                    <button className="badge" style={{
                                        height: "80%", width: "100%", color: 'white',
                                        border: '1px solid white', padding: "0.6rem", textAlign: 'center',
                                        background: 'transparent', cursor: 'pointer'
                                    }}>View Profile</button>
                                </a>
                            </td>
                            <td>
                                <i className="fa-solid fa-trash"></i>
                                <i className="fa-solid fa-pen-to-square"></i>
                            </td>
                        </tr>
                    );
                }
                return rows;
            }, [])}
        </tbody>
        
                                </table>
                            </div>
                        </div>
                    </div>
        
                    <div className="d-flex justify-content-center mt-3">
                <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
                <Paginations
          count={Math.ceil(mapDatas.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="secondary"
        />
          </Stack>
        </div>
                      
                </CrmAdminFranchiseViewContainer>
            );
        }
        }


export default CrmUserVipViewAddlead;

                                    