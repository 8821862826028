import React, { useEffect, useState } from 'react'
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
function DashBoardThird({accountDetail,accounts}) {
  let [adminData, setAdminData] = useState();
    let { profileData } = useGlobalContext([]);
  let data = accountDetail;
  console.log(adminData);
  function calculateTotalSums(dataArray) {
    // Initialize an object to hold the total sums
    const totalSums = {
      collection: 0,
      currentMonthBusiness: 0,
      lastMonthBusiness: 0,
      revenue: 0,
      totalBusiness: 0,
      wallet: 0,
    };
    if (dataArray) {
      dataArray?.map((item) => {
        const transactionDetail = item.transactionDetail;

        // Sum up each key in the transactionDetail object
        totalSums.collection += transactionDetail.collection || 0;
        totalSums.currentMonthBusiness +=
          transactionDetail.currentMonthBusiness || 0;
        totalSums.lastMonthBusiness += transactionDetail.lastMonthBusiness || 0;
        totalSums.revenue += transactionDetail.revenue || 0;
        totalSums.totalBusiness += transactionDetail.totalBusiness || 0;
        totalSums.wallet += transactionDetail.wallet || 0;
      });
    }
    // Iterate over each item in the array
    

    // Return the aggregated totals
    return totalSums;
  }
  useEffect(() => {
    if (profileData.role == "admin" || profileData.role == "hos"&&accounts) {
      const aggregatedTotals = calculateTotalSums(accounts);
      setAdminData(aggregatedTotals);
    }
  }, []);
  if (profileData.role == "admin" || profileData.role == "hos" ) {
    return (
      <div className="container pt-3">
        <div className="row">
          <div className="col-12 col-xs-12  col-sm-12 col-md-12 col-lg-4  mt-3">
            <div className=" wallet-container justify-content-between">
              <div className="wallent-content">
                <h5>Total Business</h5>
                <h1 style={{ fontSize: "4rem" }}>{adminData?.totalBusiness}</h1>
              </div>
              <span>₹</span>
            </div>
          </div>

          <div className="col-12 col-xs-12  col-sm-12 col-md-6 col-lg-4 mt-3">
            <div className=" wallet-container justify-content-between">
              <div className="wallent-content">
                <h5>Last Month Business</h5>
                <h1 style={{ fontSize: "4rem" }}>
                  {adminData?.lastMonthBusiness}
                </h1>
              </div>
              <span>₹</span>
            </div>
          </div>
          <div className="col-12 col-xs-12  col-sm-12 col-md-6 col-lg-4 mt-3">
            <div className=" wallet-container justify-content-between">
              <div className="wallent-content">
                <h5>Current Month Business</h5>
                <h1 style={{ fontSize: "4rem" }}>
                  {adminData?.currentMonthBusiness}
                </h1>
              </div>
              <span>₹</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
console.log(accountDetail);
if (accountDetail){
 return (
   <div className="container pt-3">
     <div className="row">
       <div className="col-12 col-xs-12  col-sm-12 col-md-12 col-lg-4  mt-3">
         <div className=" wallet-container justify-content-between">
           <div className="wallent-content">
             <h5>Total Business</h5>
             <h1 style={{ fontSize: "4rem" }}>
               {accountDetail[0]?.transactionDetail.totalBusiness}
             </h1>
           </div>
           <span>₹</span>
         </div>
       </div>

       <div className="col-12 col-xs-12  col-sm-12 col-md-6 col-lg-4 mt-3">
         <div className=" wallet-container justify-content-between">
           <div className="wallent-content">
             <h5>Last Month Business</h5>
             <h1 style={{ fontSize: "4rem" }}>
               {accountDetail[0]?.transactionDetail.lastMonthBusiness}
             </h1>
           </div>
           <span>₹</span>
         </div>
       </div>
       <div className="col-12 col-xs-12  col-sm-12 col-md-6 col-lg-4 mt-3">
         <div className=" wallet-container justify-content-between">
           <div className="wallent-content">
             <h5>Current Month Business</h5>
             <h1 style={{ fontSize: "4rem" }}>
               {accountDetail[0]?.transactionDetail.currentMonthBusiness}
             </h1>
           </div>
           <span>₹</span>
         </div>
       </div>
     </div>
   </div>
 );
}
 
    return<></>
      
}

export default DashBoardThird